import React, { useState, useEffect } from 'react';
import { Button, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid, TextField, Dialog, DialogTitle, DialogContent, DialogActions  } from '@mui/material';
import { API_URLS } from '../../../config/config';
import ResultDialog from '../../../pages/components/Auth/Dialog';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Stack from '@mui/material/Stack'; 
import { useNavigate } from 'react-router-dom';


const SehirVeIlcelerFormu = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  
  const [sehirListesi, setSehirListesi] = useState([]);
  const [shouldFetch, setShouldFetch] = useState(true);
  const [yeniSehir, setYeniSehir] = useState('');
  const [yeniPlaka, setYeniPlaka] = useState('');
  const [yeniIlceler, setYeniIlceler] = useState('');
  // Güncelleme için ayrı state'ler
  const [guncelleSehir, setGuncelleSehir] = useState('');
  const [guncellePlaka, setGuncellePlaka] = useState('');
  const [guncelleIlceler, setGuncelleIlceler] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');
  const [dialogSeverity, setDialogSeverity] = useState('info');
  const [seciliSehirId, setSeciliSehirId] = useState(null);
  const [eklemeBasariliDialogOpen, setEklemeBasariliDialogOpen] = useState(false);
  const [silmeBasariliDialogOpen, setSilmeBasariliDialogOpen] = useState(false); 
  
   

  //listeleme
   useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('userToken');
      if (!token) {      
        navigate('/');
      } else {
        try {
          const response = await fetch(API_URLS.ADMIN_URL + 'me', {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
          if (response.ok) {
            const data = await response.json();
            setUserData(data);
           
          } else {
       throw new Error('Veri alınırken bir hata oluştu');
    
          }
        } catch (error) {
          console.error(error);
          navigate('/login');
        }
      }
    };
    
    const fetchSehirler = async () => {
      if (!shouldFetch) return;  
      try {
        const response = await fetch(API_URLS.ADMIN_URL + "sehirler");
        if (!response.ok) {
          throw new Error('Veri çekme işlemi başarısız');
        }
        const sehirler = await response.json();
        setSehirListesi(sehirler);
      } catch (err) {
        console.error("Şehirler yüklenirken bir hata oluştu:", err.message);
      } finally {
        setShouldFetch(false);
      }
    };
  
    fetchUserData();
    fetchSehirler();
  }, [shouldFetch]); 
  


//ekleme
const handleEkle = async () => {
  const ilcelerArray = yeniIlceler.split(',').map(ilce => ({ ilce_adi: ilce.trim() }));

  try {
    const response = await fetch(API_URLS.ADMIN_URL + "sehirler", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        sehir_adi: yeniSehir,
        plaka_kodu: yeniPlaka,
        ilceler: ilcelerArray
      }),
    });

    const kaydedilenSehir = await response.json();

    if (!response.ok) {
      throw new Error(kaydedilenSehir.message || 'Şehir kaydedilirken bir hata oluştu.');
    }

    setSehirListesi([...sehirListesi, kaydedilenSehir]);
    setDialogSeverity('success');
    setDialogContent('Yeni şehir başarıyla eklendi.');
    setEklemeBasariliDialogOpen(true); 

    setYeniSehir('');
    setYeniPlaka('');
    setYeniIlceler('');
  } catch (err) {
    setDialogSeverity('error');
    setDialogContent(err.message);
    setEklemeBasariliDialogOpen(true); 
  }
};

    //güncelle
    const handleGuncelleClick = (sehirId) => {
      const sehir = sehirListesi.find(s => s._id === sehirId);
      if (sehir) {
        setGuncelleSehir(sehir.sehir_adi);
        setGuncellePlaka(sehir.plaka_kodu);
        setGuncelleIlceler(sehir.ilceler.map(ilce => ilce.ilce_adi).join(', '));
        setSeciliSehirId(sehirId);
        setDialogOpen(true); 
      } else {
        console.error('Seçilen şehir bulunamadı.');
      }
    };
  
    const handleDialogClose = () => {
      setDialogOpen(false);
    };
  

  const handleGuncelleSubmit = async () => {
  // İlçeleri diziye çevirme
  const ilcelerArray = guncelleIlceler.split(',').map(ilce => ({ ilce_adi: ilce.trim() }));

  try {
    const response = await fetch(API_URLS.ADMIN_URL + `sehirler/${seciliSehirId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        sehir_adi: guncelleSehir,
        plaka_kodu: guncellePlaka,
        ilceler: ilcelerArray,
      }),
    });

    const guncellenmisSehir = await response.json();

    if (!response.ok) {
      throw new Error(guncellenmisSehir.message || 'Şehir güncellenirken bir hata oluştu.');
    }

    // Güncellenen şehirle eski listeyi güncelleme
    setSehirListesi(sehirListesi.map(sehir => sehir._id === seciliSehirId ? guncellenmisSehir : sehir));

    setDialogSeverity('success');
    setDialogContent('Şehir başarıyla güncellendi.');
    setEklemeBasariliDialogOpen(true); 

    setDialogOpen(false);
    setGuncelleSehir('');
    setGuncellePlaka('');
    setGuncelleIlceler('');
  } catch (err) {
    console.error('Güncelleme işlemi sırasında bir hata oluştu:', err);
    setDialogSeverity('error');
    setDialogContent(err.message || 'Güncelleme işlemi sırasında bir hata oluştu.');
    setEklemeBasariliDialogOpen(true); 
  }
};
 

  //silme 
  const handleSil = async (sehirId) => {
    const onay = window.confirm('Bu şehri silmek istediğinize emin misiniz?');
    if (!onay) return;
  
    try {
      const response = await fetch(API_URLS.ADMIN_URL + `sehirler/${sehirId}`, {
        method: 'DELETE',
      });
  
      const data = await response.json();
  
      if (!response.ok) {
        setDialogSeverity('error');
        setDialogContent(data.message || 'Şehir silinirken bir hata oluştu.');
        setSilmeBasariliDialogOpen(true); // Burayı güncelledik
      } else {
        setSehirListesi(sehirListesi.filter(sehir => sehir.id !== sehirId));
        setDialogSeverity('success');
        setDialogContent(`${data.message}`);
        setSilmeBasariliDialogOpen(true); // Ve burayı da
      }
    } catch (err) {
      setDialogSeverity('error');
      setDialogContent('Hata: ' + err.message);
      setSilmeBasariliDialogOpen(true); // Hata durumunda da kullanılacak
    }
  };

  const handleSilmeDialogClose = () => {
    setSilmeBasariliDialogOpen(false);
  };
  


  return (
    <Paper style={{ padding: 20 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <Typography variant="h5" component="h3" marginBottom={2}>
            Şehir Ekle
          </Typography>
          <TextField fullWidth label="Şehir Adı" value={yeniSehir} onChange={(e) => setYeniSehir(e.target.value)} margin="normal" />
          <TextField fullWidth label="Plaka Kodu" value={yeniPlaka} onChange={(e) => setYeniPlaka(e.target.value)} margin="normal" />
          <TextField fullWidth label="İlçeler (virgülle ayır)" value={yeniIlceler} onChange={(e) => setYeniIlceler(e.target.value)} margin="normal" />
          <Button onClick={handleEkle} variant="contained" color="primary">
            Ekle
          </Button>
        </Grid>
        <Grid item xs={12} md={9}>
          <Typography variant="h5" component="h3" marginBottom={2}>
            Şehir ve İlçeler Listesi
          </Typography>
          <TableContainer component={Paper}>
            <Table aria-label="şehir listesi">
              <TableHead>
                <TableRow>
                  <TableCell>Şehir Adı</TableCell>
                  <TableCell align="right">Plaka Kodu</TableCell>
                  <TableCell align="right">İlçeler</TableCell>
                  <TableCell align="right">İşlem</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
           
          {sehirListesi.map((sehir) => (
            <TableRow key={sehir.id}>
              <TableCell component="th" scope="row">
                {sehir.sehir_adi} 
              </TableCell>
              <TableCell align="right">{sehir.plaka_kodu}</TableCell>
              <TableCell align="right">
                {sehir.ilceler.map(ilce => ilce.ilce_adi).join(', ')}
              </TableCell>
            
            <TableCell align="right">
              <Stack direction="row" spacing={1}> {/* Butonları yanyana koy ve aralarında boşluk bırak */}
              <Button
                onClick={() => handleGuncelleClick(sehir._id)} // Burası düzeltilmeli.
                variant="outlined"
                color="primary"
                size="small"
                startIcon={<EditIcon />}
                sx={{
                  mr: 1, 
                  '&:hover': {
                    backgroundColor: 'lightblue', 
                    color: '#fff', 
                  }
                }}
              >
                Güncelle
              </Button>

                <Button
                  onClick={() => handleSil(sehir._id)}
                  variant="contained"
                  color="secondary"
                  size="small"
                  startIcon={<DeleteIcon />}
                  sx={{
                    '&:hover': {
                      backgroundColor: 'red', 
                    },
                    boxShadow: 'none', 
                  }}
                >
                  Sil
                </Button>
              </Stack>
            </TableCell>
            </TableRow>
          ))}
              </TableBody>
            </Table>
          </TableContainer>

          <>
          <ResultDialog
            open={eklemeBasariliDialogOpen}
            onClose={() => setEklemeBasariliDialogOpen(false)}
            title={dialogSeverity === 'error' ? "Hata Oluştu" : "İşlem Başarılı"}
            message={dialogContent}
            severity={dialogSeverity}
          />
          <ResultDialog
            open={silmeBasariliDialogOpen}
            onClose={() => setSilmeBasariliDialogOpen(false)}
            title={dialogSeverity === 'error' ? "Hata Oluştu" : "Silme İşlemi Başarılı"}
            message={dialogContent}
            severity={dialogSeverity}
          />
        </>

              <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Şehir Güncelle</DialogTitle>
                <DialogContent>
                  <TextField
                    autoFocus
                    margin="dense"
                    label="Şehir Adı"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={guncelleSehir}
                    onChange={(e) => setGuncelleSehir(e.target.value)}
                  />
                  <TextField
                    margin="dense"
                    label="Plaka Kodu"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={guncellePlaka}
                    onChange={(e) => setGuncellePlaka(e.target.value)}
                  />
                  <TextField
                    margin="dense"
                    label="İlçeler (virgülle ayır)"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={guncelleIlceler}
                    onChange={(e) => setGuncelleIlceler(e.target.value)}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleDialogClose}>İptal</Button>
                  <Button onClick={handleGuncelleSubmit}>Güncelle</Button>
                </DialogActions>
              </Dialog>



        </Grid>
      </Grid>
    </Paper>
  );
};

export default SehirVeIlcelerFormu;
