import React, { useState } from 'react';
import {  Container,  Typography,  Button,  Stack,  useTheme,  Card,  CardContent,
  CardActionArea,
} from '@mui/material';
import LightbulbIcon from '@mui/icons-material/Lightbulb'; 
import TimerIcon from '@mui/icons-material/Timer'; 
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter'; 
import Header from '../home/components/Header';
import Footer from '../home/components/Footer';
import '../assets/Testler.css';




function Testler() {
   const theme = useTheme();
  const [difficulty, setDifficulty] = useState('kolay');

  const tests = [
    {
      id: 1,
      title: 'Temel Seviye Hızlı Okuma',
      description: 'Bu test, hızlı okuma becerilerinizin temel seviyesini ölçer ve pratik yapmanızı sağlar.',
      difficulty: 'kolay',
    },
    {
      id: 2,
      title: 'Orta Seviye Kelime Tanıma',
      description: 'Orta seviye kelime tanıma becerilerinizi geliştirmek için bu testi çözün.',
      difficulty: 'orta',
    },
    {
      id: 3,
      title: 'Gelişmiş Anlama ve Kritik Düşünme',
      description: 'Metni hızlıca okuyup anladığınızı ve kritik düşünebildiğinizi test edin.',
      difficulty: 'zor',
    },
    {
      id: 4,
      title: 'Zamanlı Okuma Testi',
      description: 'Belirlenen zaman içinde ne kadar metin okuyup anlayabileceğinizi ölçün.',
      difficulty: 'kolay',
    },
    {
      id: 5,
      title: 'Detayları Yakalama Testi',
      description: 'Metindeki detayları ne kadar hızlı ve doğru bir şekilde yakalayabileceğinizi görün.',
      difficulty: 'orta',
    },
    {
      id: 6,
      title: 'Zor Metinleri Anlama',
      description: 'Yoğun ve karmaşık metinleri ne kadar hızlı anlayabileceğinizi test edin.',
      difficulty: 'zor',
    },
  
  ];
  

  const handleDifficultyChange = (newDifficulty) => {
    setDifficulty(newDifficulty);
  };

  return (
    <div>
      <Header />
      <div  className='bg-main'>
      <Container 
        maxWidth="md"
        className='main-container'
        sx={{          
          mb: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          bgcolor: 'background.default',        
          borderRadius: '12px',         
          color: theme.palette.common.white,         
        }}
      >
        <Typography variant="h4" component="h1"  gutterBottom>
          Testler Kısmı
        </Typography>

        <Stack direction="row" spacing={2} mb={4} justifyContent="center">
        {[
          { level: 'kolay', icon: <LightbulbIcon />, label: 'Kolay' },
          { level: 'orta', icon: <TimerIcon />, label: 'Orta' },
          { level: 'zor', icon: <FitnessCenterIcon />, label: 'Zor' },
        ].map((option) => (
      
          
        <Button
          key={option.level}
          variant="contained"
          onClick={() => handleDifficultyChange(option.level)}
          startIcon={option.icon}
          sx={{
            color: difficulty === option.level ? theme.palette.common.white : theme.palette.primary.main,
            bgcolor: difficulty === option.level ? theme.palette.primary.main : 'transparent', 
            border: difficulty === option.level ? 'none' : `1px solid ${theme.palette.primary.main}`,
            boxShadow: difficulty === option.level ? '0 4px 20px rgba(0,0,0,0.25)' : '0 2px 10px rgba(0,0,0,0.1)', 
            transition: 'transform 0.3s, box-shadow 0.3s, background-color 0.3s',
            '&:hover': {
              transform: 'scale(1.1)',
              boxShadow: '0 6px 20px rgba(0,0,0,0.3)', 
              bgcolor: 'linear-gradient(145deg, #64748B, #546E7A)', 
              color: theme.palette.common.white ,
            },
            '&:active': {
              boxShadow: '0 2px 14px rgba(0,0,0,0.2)' 
            }
          }}
        >
          {option.label}
        </Button>        
        ))}
      </Stack>

   
         <Stack 
          direction="column" 
          spacing={1} 
          sx={{ 
            width: '100%',
            maxWidth: '100%',
            '.MuiPaper-root': {
           
              '&:not(:last-child)': {
                borderBottom: 0,
              },
              overflow: 'auto', 
              maxHeight: '75vh', 
            },        
            
          }}
        >
          {tests
            .filter(test => test.difficulty === difficulty)
            .map((test, index) => (
              <Card key={index} 
              elevation={4} 
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: 1,
                m: 0, 
                transition: 'transform 0.3s, box-shadow 0.3s',
                '&:hover': {
                  transform: 'translateX(10px)',
                  boxShadow: '0 8px 24px rgba(0,0,0,0.6)',
                },
                bgcolor: theme.palette.primary.main,
                backgroundImage: `linear-gradient(145deg, ${theme.palette.primary.main}, #64748B)`, // Grimsi mavi gradient
               
                color: theme.palette.primary.contrastText,
                borderRadius: '20px', 
                boxShadow: '0 8px 24px rgba(0,0,0,0.3)',
              }}
              
              >
                <CardActionArea>
                  <CardContent>
                    <Typography variant="h5">{test.title}</Typography>
                    <Typography variant="body2">{test.description}</Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
          ))}
        </Stack>
      </Container>
      </div>
      <Footer />
    </div>
  );
}

export default Testler;
