import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Typography, Drawer, List, ListItem, ListItemIcon, ListItemText, CssBaseline,  Collapse } from '@mui/material';
import { Menu as MenuIcon, Dashboard as DashboardIcon, Mail as MailIcon, School as SchoolIcon, Settings as SettingsIcon, Logout as LogoutIcon,
  ExpandLess, ExpandMore, Payment as PaymentIcon, Report as ReportIcon, LocationCity as CityIcon, Business as BranchIcon,
  Person as TeacherIcon, Event as EventIcon, List as ListIcon, ContactPhone as ContactPhoneIcon, AccountCircle as AccountCircleIcon, AdminPanelSettings as AdminPanelSettingsIcon, Group as StudentsIcon, People as PeopleIcon, Security as SecurityIcon, LockOpen as LockOpenIcon } from '@mui/icons-material';  
import SehirlerContent from './kurumlar/Sehirler';
import KurumlarContent from './kurumlar/Kurumlar';
import OkullarContent from './kurumlar/Okullar';
import Users from './users/Users';
import Ogretmen from './ogretmen/Ogretmen';
import Ogrenci from './ogrenci/Ogrenci';
import RolePermissionManagement from './users/Role';
import { API_URLS } from '../../config/config';
import useFetchUserData from '../../hooks/admin/useFetchUserData';

 
function AdminDashboard() {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate(); 
  const [open, setOpen] = useState({});
  const [selectedMenu, setSelectedMenu] = useState('');
  
  useFetchUserData(setUserData);

  const [userRoles, setUserRoles] = useState([]);
  const [userAccessControls, setUserAccessControls] = useState([]);

  useEffect(() => {  
    if (userData) {
      const roleNames = userData.roles.map(role => role.name);      
      setUserRoles(roleNames);  
 
      const resourceNames = userData.roles
        .flatMap(role => role.accessControl)
        .flatMap(ac => ac.resources.map(resource => resource.name)); // Kaynak isimlerini al
      
      setUserAccessControls(resourceNames);
    }
  }, [userData]); 
 
  const isAdmin = userData && userData.roles?.some(role => role.name === 'Admin');

  const handleClick = (menu) => {
    setOpen((prevOpen) => ({...prevOpen, [menu]: !prevOpen[menu]}));
  };

  const drawerWidth = 240;
  const appBarStyle = {
    width: `calc(100% - ${drawerOpen ? drawerWidth : 0}px)`,
    transition: 'width 0.3s',
  };
  const drawerStyle = {
    width: drawerWidth,
    flexShrink: 0,
  };
  const drawerPaperStyle = {
    width: drawerWidth,
  };

  const contentStyle = {
    flexGrow: 1,
    padding: '24px',
    transition: 'margin-left 0.3s',
    marginLeft: `10px`,
    marginTop: '64px', 
  };  

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen); 
  };

  const handleLogout = async () => {
    try {
      
      await fetch(API_URLS.ADMIN_URL + 'logout', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('userToken')}`,
          'Content-Type': 'application/json',
        },
      });
  
      localStorage.removeItem('userToken');
      navigate('/login');
    } catch (error) {
      console.error('Çıkış işlemi sırasında bir hata oluştu', error);
    }
  };

  const menuItems = [
    { text: 'Anasayfa', icon: <DashboardIcon />, action: () => {} },
    ...(isAdmin ? [{
      text: 'Kullanıcılar',
      icon: <PeopleIcon />,
      subMenus: [
        { text: 'Kullanıcılar', icon: <StudentsIcon />, action: () => setSelectedMenu('Kullanıcılar') },
        { text: 'Roller ve Yetkiler', icon: <SecurityIcon />, action: () => setSelectedMenu('Roller') },
      ],
    }] : []),
    {
      text: 'Kurumlar',
      icon: <SchoolIcon />,
      subMenus: [
        { text: 'Şehirler', icon: <CityIcon />, action: () => setSelectedMenu('Şehirler') },
        { text: 'Şubeler', icon: <BranchIcon />,action: () => setSelectedMenu('Şubeler') },
        { text: 'Okullar', icon: <BranchIcon />,action: () => setSelectedMenu('Okullar') },
      ],
    },

    { text: 'Öğretmenler', icon: <TeacherIcon />, 
    
    subMenus: [
      { text: 'Tüm Liste', icon: <TeacherIcon />, action: () => setSelectedMenu('Öğretmenler') },
    
    ],
  
  },
    { text: 'Öğrenciler', icon: <StudentsIcon />,
    subMenus: [
      { text: 'Tüm Liste', icon: <StudentsIcon />, action: () => setSelectedMenu('Öğrenciler') },
    
    ],  
  
  },
    {
      text: 'Raporlar',
      icon: <ReportIcon />,
      subMenus: [
        { text: 'Öğretmenler', icon: <TeacherIcon /> },
        { text: 'Ödemeler', icon: <PaymentIcon /> },
        { text: 'Etkinlikler', icon: <EventIcon /> },
      ],
    },

    {
      text: 'Ödeme',
      icon: <PaymentIcon />,
      subMenus: [
        { text: 'Ödeme formu sayfası', icon: <PaymentIcon /> },
        { text: 'Ödeme listesi', icon: <ListIcon /> },
      ],
    },
    { text: 'Mail', icon: <MailIcon />, action: () => {} },
    {
      text: 'Ayarlar',
      icon: <SettingsIcon />,
      subMenus: [
        { text: 'İletişim', icon: <ContactPhoneIcon /> },
        { text: 'Profil ayarları', icon: <AccountCircleIcon /> },
     
      ],
    },
    { text: 'Çıkış Yap', icon: <LogoutIcon />, action: handleLogout },
  ];

  const renderContent = () => {
    if (!userData) {
      return <Typography paragraph>Giriş yapınız.</Typography>;
    }
  
    
    switch (selectedMenu) {
      case 'Şehirler':
        if (userAccessControls.includes('Şehirler')) {
          return <SehirlerContent />;
        }
        break;
      case 'Şubeler':
        if (userAccessControls.includes('Şubeler')) {
          return <KurumlarContent />;
        }
        break;
      case 'Okullar':
        if (userAccessControls.includes('Okullar')) {
          return <OkullarContent />;
        }
        break;
      case 'Kullanıcılar':
        if (userAccessControls.includes('Kullanıcılar') && userData.roles?.some(role => role.name === 'Admin')) {
          return <Users />;
        } else {
          return <Typography paragraph>Hoş Geldiniz Sayın - {userData.fullname} - Yönetim paneline erişim izniniz yok.</Typography>;
        }
      case 'Roller':
        if (userAccessControls.includes('Roller') && userData.roles?.some(role => role.name === 'Admin')) {
          return <RolePermissionManagement />;
        } else {
          return <Typography paragraph>Hoş Geldiniz Sayın - {userData.fullname} - Roller yönetimine erişim izniniz yok.</Typography>;
        }

      case 'Öğretmenler':        
      return <Ogretmen />;
      break;
      case 'Öğrenciler':        
      return <Ogrenci />;
      break;

      default:
      
        return (
          <Typography paragraph>
            Hoş Geldiniz Sayın - {userData.fullname} - Rolleriniz: {userData.roles?.map(role => role.name).join(', ')} - Yetkili Olduğunuz Modüller: {userAccessControls.join(', ')}
          </Typography>
        );
    }
  
    
    return <Typography paragraph>Seçilen modüle erişim izniniz bulunmamaktadır.</Typography>;
  };
    
  
  return (
    <div style={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" style={appBarStyle}>
        <Toolbar>
          <IconButton 
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={toggleDrawer}
            style={{ marginRight: '20px', ...(drawerOpen && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
           Kurum Panel
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="persistent"
        open={drawerOpen}
        style={drawerStyle}
        sx={{ '& .MuiDrawer-paper': drawerPaperStyle }}
      >
<List>
  {menuItems.map((item, index) => (
    <React.Fragment key={index}>
      <ListItem button onClick={() => item.action ? item.action() : handleClick(item.text)}>
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText primary={item.text} />
        {item.subMenus ? (open[item.text] ? <ExpandLess /> : <ExpandMore />) : null}
      </ListItem>
      {item.subMenus && (
        <Collapse in={open[item.text]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {item.subMenus.map((subItem, subIndex) => (
              <ListItem button key={subIndex} onClick={subItem.action}>
                <ListItemIcon>{subItem.icon}</ListItemIcon>
                <ListItemText primary={subItem.text} />
              </ListItem>
            ))}
          </List>
        </Collapse>
      )}
    </React.Fragment>
  ))}
</List>

      </Drawer>
      <main style={contentStyle}>
    
        {renderContent()}
      </main>
    </div>
  );
}

export default AdminDashboard;
