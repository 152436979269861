
import './assets/App.css';
import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { BrowserRouter as Router } from 'react-router-dom';
import HomeRouter from './route/HomeRouter';
import EgzersizRouter from './route/EgzersizRouter';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AuthRouter from './route/AuthRouter';

const theme = createTheme({
  components: {
    MuiCircularProgress: {
      styleOverrides: {
        root: {
        
        },
        circle: {
          stroke: 'url(#gradient)', 
        },
      },
    },
  },
});


function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
      const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            bgcolor: 'background.paper',
          }}
        >
      
           <svg width="0" height="0">
          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop stopColor="#f3ec78" offset="0%" />
            <stop stopColor="#af4261" offset="100%" />
          </linearGradient>
        </svg>
            <CircularProgress size={250} thickness={2} />
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
            
              <img
                src="/images/load_spin.png" 
                alt="Loading"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '25vh',
                }}
              />
            </Box>

            <div className="spin-around"></div>
          </Box>       
      ) : (
    
        <Router>
        <HomeRouter />
        <EgzersizRouter />
        <AuthRouter />
    
      </Router>
      )}
    </ThemeProvider>
  );
}

export default App;


