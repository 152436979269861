import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../home/containers/Home';
import MainLogin from '../home/containers/MainLogin';
import MainRegister from '../home/containers/MainRegister';
import HizliOku from '../pages/HizliOku';
import Egzersiz from '../pages/Egzersiz';
import Testler from '../pages/Testler';

function HomeRouter() {
    return (
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/hizlioku" element={<HizliOku />} />
          <Route path="/egsersizler" element={<Egzersiz />} />
          <Route path="/testler" element={<Testler />} />
          <Route path="/login" element={<MainLogin />} />
          <Route path="/register" element={<MainRegister />} />
      
      </Routes>
    );
  }
  
  export default HomeRouter;