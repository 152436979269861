import React, { useState, useEffect } from 'react';
import { Button, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid, TextField, Dialog, DialogTitle, DialogContent, DialogActions,
  FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import ResultDialog from '../../../pages/components/Auth/Dialog';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Stack from '@mui/material/Stack';
import { API_URLS } from '../../../config/config';

const Ogretmen = () => {
const [ogretmenListesi, setOgretmenListesi] = useState([]);
const [sehirListesi, setSehirListesi] = useState([]);  
const [secilenSehirId, setSecilenSehirId] = useState('');
const [secilenIlceId, setSecilenIlceId] = useState("");
const [shouldFetch, setShouldFetch] = useState(true);
const [ad, setAd] = useState('');
const [soyad, setSoyad] = useState('');
const [okulAdi, setOkulAdi] = useState('');
const [telefon, setTelefon] = useState('');
const [telefonHata, setTelefonHata] = useState(false);
const [email, setEmail] = useState('');
const [sifre, setSifre] = useState('');
const [dialogContent, setDialogContent] = useState('');
const [dialogSeverity, setDialogSeverity] = useState('info');
const [dialogOpen, setDialogOpen] = useState(false);
const [resultDialogOpen, setResultDialogOpen] = useState(false);
const [resultDialogMessage, setResultDialogMessage] = useState('');
const [resultDialogSeverity, setResultDialogSeverity] = useState('info');
const [eklemeBasariliDialogOpen, setEklemeBasariliDialogOpen] = useState(false); 
const [duzenlemeDialogAcik, setDuzenlemeDialogAcik] = useState(false);

const [searchQuery, setSearchQuery] = useState('');
const filteredOgretmenListesi = ogretmenListesi.filter(ogretmen =>
  ogretmen.ad.toLowerCase().includes(searchQuery.toLowerCase()) ||
  ogretmen.soyad.toLowerCase().includes(searchQuery.toLowerCase()) ||
  ogretmen.telefon.includes(searchQuery) ||
  ogretmen.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
  (ogretmen.sehirAdi && ogretmen.sehirAdi.toLowerCase().includes(searchQuery.toLowerCase())) ||
  (ogretmen.ilceAdi && ogretmen.ilceAdi.toLowerCase().includes(searchQuery.toLowerCase()))
);

const [duzenlenenOgretmen, setDuzenlenenOgretmen] = useState({
  ad:'',
  soyad:'',
  okulAdi: '',
  email: '',
  telefon: '',
  sehirId: '',
  ilceId: '', 
  ilceler: [],
});

const fetchIlceler = async (sehirId, seciliIlceId) => {
  try {
    const response = await fetch(`${API_URLS.ADMIN_URL}sehirler/ilceler/${sehirId}`);
    if (!response.ok) throw new Error('İlçeler yüklenirken bir hata oluştu.');
    const data = await response.json(); 
    const ilceler = data.ilceler ? data.ilceler : []; 

    setDuzenlenenOgretmen(prev => ({ 
      ...prev, 
      ilceler: ilceler,
   
      ilceId: seciliIlceId && ilceler.some(ilce => ilce._id === seciliIlceId) ? seciliIlceId : prev.ilceId 
    }));
  } catch (error) {
    console.error('İlçeleri yüklerken bir hata oluştu:', error);
  }
}; 

  useEffect(() => {
  const fetchSehirler = async () => {
    if (!shouldFetch) return;

    try {
      const response = await fetch(API_URLS.ADMIN_URL + "sehirler");
      if (!response.ok) {
        throw new Error('Veri çekme işlemi başarısız');
      }
      const sehirler = await response.json();    
      setSehirListesi(sehirler);

    } catch (err) {
      console.error("Şehirler yüklenirken bir hata oluştu:", err.message);
    } finally {
      setShouldFetch(false);
    }
  };

    const fetchOgretmenListesi = async () => {
      try {
        const response = await fetch(`${API_URLS.ADMIN_URL}teachers`); 
        if (!response.ok) {
          throw new Error('Öğretmen listesi yüklenirken bir hata oluştu.');
        }
        const data = await response.json();      
        setOgretmenListesi(data);
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchSehirler();
    fetchOgretmenListesi();
  }, []);

  const handleDialogClose = () => {
    setDialogOpen(false);
    setResultDialogOpen(false); 
  };
 
  const handleTelefonChange = (event) => {
    const telefonRegex = /^0(\d{3}) (\d{3}) (\d{4})$/;
    let { value } = event.target;
    const numbers = value.replace(/\D/g, '');  
    value = numbers.substring(0, 4) + (numbers.length > 4 ? ' ' : '') + numbers.substring(4, 7) + (numbers.length > 7 ? ' ' : '') + numbers.substring(7, 11); 
    setTelefonHata(!telefonRegex.test(value) && value !== '');
    setTelefon(value);
  }; 
  const handleSehirChange = (event) => {
    setSecilenSehirId(event.target.value);
  };  

  const handleIlceChange = (event) => {
    setSecilenIlceId(event.target.value);
  };

  const handleSehirChangeDuzenle = (event) => {
    const yeniSehirId = event.target.value; 
    setDuzenlenenOgretmen(prev => ({ ...prev, sehirId: yeniSehirId }));  
    const secilenSehir = sehirListesi.find(sehir => sehir._id === yeniSehirId);
    const ilceler = secilenSehir ? secilenSehir.ilceler : [];  
    setDuzenlenenOgretmen(prev => ({ ...prev, ilceId: '', ilceler: ilceler }));
  };

  const handleIlceChangeDuzenle = (event) => {
    const yeniIlceId = event.target.value; 
    setDuzenlenenOgretmen(prev => ({ ...prev, ilceId: yeniIlceId }));
  };

  const secilenSehirinIlceleri = sehirListesi.find(sehir => sehir._id === secilenSehirId)?.ilceler || [];

  const handleEkle = async () => {
    let eksikAlanlar = [];
  
    if (!ad) eksikAlanlar.push('Adı');
    if (!soyad) eksikAlanlar.push('Soyadı');
    if (!email) eksikAlanlar.push('Email');
    if (!sifre) eksikAlanlar.push('Şifre');
    if (!telefon) eksikAlanlar.push('Telefon');
    if (!secilenSehirId) eksikAlanlar.push('Şehir');
    if (!secilenIlceId) eksikAlanlar.push('İlçe');
  
    if (eksikAlanlar.length > 0) {
      setDialogSeverity('error');
      setDialogContent(`Lütfen ${eksikAlanlar.join(', ')} alanlarını doldurunuz.`);
      setEklemeBasariliDialogOpen(true);
      return;
    }  
 
    if (telefonHata) {
      setDialogSeverity('error');
      setDialogContent('Lütfen geçerli bir telefon numarası giriniz.');
      setEklemeBasariliDialogOpen(true);
      return;
    }

    try {  
      const response = await fetch(API_URLS.ADMIN_URL + 'teachers', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ad:ad,
          soyad:soyad,
          email: email,
          password : sifre,
          okul_adi:okulAdi,
          telefon : telefon,
          sehir: secilenSehirId,
          ilce: secilenIlceId,      

        }),
      });

      const responseData = await response.json();
      if (response.ok) {
        setOgretmenListesi([...ogretmenListesi, responseData]); 
        
        setDialogSeverity('success');
        setDialogContent('Yeni Öğretmen başarıyla eklendi.');
        setEklemeBasariliDialogOpen(true);         

        setAd('');
        setSoyad('');
        setOkulAdi('');
        setEmail('');
        setSifre('');     
        setTelefon('');
        setSecilenSehirId('');
        setSecilenIlceId('');
      
      } else {
       
        throw new Error(responseData.message || 'Bir hata oluştu');
      }
    } catch (err) {
      setDialogSeverity('error');
      setDialogContent(err.message);
      setEklemeBasariliDialogOpen(true); 
    }
  };

  const handleDeleteOgretmen = async (ogretmenId) => {
    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}teachers/${ogretmenId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) throw new Error('Öğretmen silinirken bir hata oluştu.');  
     
      setOgretmenListesi(ogretmenListesi.filter((ogretmen) => ogretmen._id !== ogretmenId));
  
      setResultDialogMessage('Admin başarıyla silindi.');
      setResultDialogSeverity('success');
      setResultDialogOpen(true);
    } catch (error) {
      setResultDialogMessage(error.message);
      setResultDialogSeverity('error');
      setResultDialogOpen(true);
    }
  };

  const handleEdit = (ogretmenId) => {
    const seciliOgretmen = ogretmenListesi.find(ogretmen => ogretmen._id === ogretmenId);

    if (seciliOgretmen) {
 
      const sehirId = seciliOgretmen.sehir;

      setDuzenlenenOgretmen(prev => ({
        ...prev,
        _id: seciliOgretmen._id,
        ad:seciliOgretmen.ad,
        soyad:seciliOgretmen.soyad,
        okulAdi: seciliOgretmen.okul_adi,
        email: seciliOgretmen.email,
        telefon: seciliOgretmen.telefon,
        sehirId: sehirId, 
        ilceId: seciliOgretmen.ilce, 
    
      }));  
      fetchIlceler(sehirId);   
      setDuzenlemeDialogAcik(true);
    } else {
      console.error('Seçilen kurum bulunamadı.');
    }
  }; 

  const handleGuncelle = async () => {
    const { ad, soyad, okulAdi, email, telefon, sehirId, ilceId } = duzenlenenOgretmen;
    let eksikAlanlar = [];
  
    if (!ad) eksikAlanlar.push('Adı');
    if (!soyad) eksikAlanlar.push('Soyadı');
    if (!email) eksikAlanlar.push('Email');
    if (!telefon) eksikAlanlar.push('Telefon');
    if (!sehirId) eksikAlanlar.push('Şehir');
    if (!ilceId) eksikAlanlar.push('İlçe');
    if (!okulAdi) eksikAlanlar.push('Okul Adı');
  
    if (eksikAlanlar.length > 0) {
      setDialogSeverity('error');
      setDialogContent(`Lütfen ${eksikAlanlar.join(', ')} alanlarını doldurunuz.`);
      setDialogOpen(true);
      return;
    }
  
    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}teachers/${duzenlenenOgretmen._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          ad, 
          soyad, 
          okul_adi: okulAdi,
          email, 
          telefon, 
          sehir: sehirId, 
          ilce: ilceId
        }),
      });
  
      const data = await response.json();
      if (!response.ok) throw new Error(data.message || 'Bir hata oluştu');
  
      // Doğru güncelleme için 'data.kurum' kullanılmalıdır.
      setOgretmenListesi(ogretmenListesi.map(kurum => kurum._id === duzenlenenOgretmen._id ? {...kurum, ...data.ogretmen} : kurum));
      setDialogSeverity('success');
      setDialogContent('Öğretmen başarıyla güncellendi.');
      setDialogOpen(true);
      setDuzenlemeDialogAcik(false);
    } catch (err) {
      setDialogSeverity('error');
      setDialogContent(err.message);
      setDialogOpen(true);
    }
  };

  const renderDuzenlemeFormuDialog = () => (
    <Dialog open={duzenlemeDialogAcik} onClose={() => setDuzenlemeDialogAcik(false)}>
      <DialogTitle>Öğretmen Düzenle</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          label="Adı"
          value={duzenlenenOgretmen.ad}
          onChange={(e) => setDuzenlenenOgretmen({...duzenlenenOgretmen, ad: e.target.value})}
          margin="normal"
        />

      <TextField
          fullWidth
          label="Soyadı"
          value={duzenlenenOgretmen.soyad}
          onChange={(e) => setDuzenlenenOgretmen({...duzenlenenOgretmen, soyad: e.target.value})}
          margin="normal"
        />

        <TextField
          fullWidth
          label="Okul Adı"
          value={duzenlenenOgretmen.okulAdi}
          onChange={(e) => setDuzenlenenOgretmen({...duzenlenenOgretmen, okulAdi: e.target.value})}
          margin="normal"
        />
         
         <FormControl fullWidth margin="dense">
          <InputLabel id="sehir-select-label-duzenle">Şehir</InputLabel>
          <Select
            labelId="sehir-select-label-duzenle"
            id="sehir-select-duzenle"
            value={duzenlenenOgretmen.sehirId}
            onChange={handleSehirChangeDuzenle}
            displayEmpty 
          >
            <MenuItem value="">
              <em>Şehir Seçiniz</em>
            </MenuItem>
            {sehirListesi.map((sehir) => (
              <MenuItem key={sehir._id} value={sehir._id}>
                {sehir.sehir_adi}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {duzenlenenOgretmen.sehirId && (
          <FormControl fullWidth margin="dense">
            <InputLabel id="ilce-select-label-duzenle">İlçe</InputLabel>
            <Select
              labelId="ilce-select-label-duzenle"
              id="ilce-select-duzenle"
              value={duzenlenenOgretmen.ilceId} 
              onChange={handleIlceChangeDuzenle}
              displayEmpty
              renderValue={(selected) => {
                if (selected) {
                  const secilenIlce = duzenlenenOgretmen.ilceler.find(ilce => ilce._id === selected);
                  return secilenIlce ? secilenIlce.ilce_adi : 'İlçe bulunamadı';
                }
                return <em>İlçe Seçiniz</em>;
              }}              
            >          
              {duzenlenenOgretmen.ilceler?.map((ilce) => ( 
                <MenuItem key={ilce._id} value={ilce._id}>
                  {ilce.ilce_adi}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <TextField
          fullWidth
          label="Email"
          value={duzenlenenOgretmen.email}
          onChange={(e) => setDuzenlenenOgretmen({...duzenlenenOgretmen, email: e.target.value})}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Telefon"
          value={duzenlenenOgretmen.telefon}
          onChange={(e) => setDuzenlenenOgretmen({...duzenlenenOgretmen, telefon: e.target.value})}
          margin="normal"
        />
   
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDuzenlemeDialogAcik(false)}>İptal</Button>
        <Button onClick={handleGuncelle} color="primary" variant="contained">Güncelle</Button> 
      </DialogActions>
    </Dialog>
  );  

return (
    <Paper style={{ padding: 20 }}>

<Grid container spacing={3}>     
        <Grid item xs={12} md={3}>
        <Typography variant="h5" component="h3" marginBottom={2}>
            Öğretmen Kayıt Formu
          </Typography>
          <TextField fullWidth label="Adı" value={ad} onChange={(e) => setAd(e.target.value)} margin="normal" />
          <TextField fullWidth label="Soyadı" value={soyad} onChange={(e) => setSoyad(e.target.value)} margin="normal" /> 
          <TextField fullWidth label="Okul Adı (isteğe bağlı)" value={okulAdi} onChange={(e) => setOkulAdi(e.target.value)} margin="normal" /> 

          <FormControl fullWidth margin="normal" >
          <InputLabel id="sehir-select-label">Şehir</InputLabel>
          <Select
            labelId="sehir-select-label"
            value={secilenSehirId}
            onChange={handleSehirChange}
            displayEmpty
            renderValue={(selected) => {
              if (selected === "") {
                return <em>Şehir Seçiniz</em>;
              }             
              const secilenSehir = sehirListesi.find(sehir => sehir._id === selected);
              return secilenSehir ? secilenSehir.sehir_adi : <em>Şehir Seçiniz</em>;
            }}
          >
            <MenuItem disabled value="">
              <em>Şehir Seçiniz</em>
            </MenuItem>
            {sehirListesi.map((sehir) => (
              <MenuItem key={sehir._id} value={sehir._id}>
                {sehir.sehir_adi}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {secilenSehirId && (
        <FormControl fullWidth margin="normal">
          <InputLabel id="ilce-select-label">İlçe</InputLabel>
          <Select
            labelId="ilce-select-label"
            id="ilce-select"
            value={secilenIlceId}
            onChange={handleIlceChange}
            displayEmpty
            renderValue={(selected) => {
              if (!selected) {
                return <em>İlçe Seçiniz</em>;
              }
              const secilenIlce = secilenSehirinIlceleri.find(ilce => ilce._id === selected);
              return secilenIlce ? secilenIlce.ilce_adi : <em>İlçe Seçiniz</em>;
            }}
          >
            <MenuItem disabled value="">
              <em>İlçe Seçiniz</em>
            </MenuItem>
            {secilenSehirinIlceleri.map((ilce) => (
              <MenuItem key={ilce._id} value={ilce._id}>
                {ilce.ilce_adi}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )} 

      <TextField
          fullWidth
          label="Telefon"
          value={telefon}
          onChange={handleTelefonChange}
          error={telefonHata}
          helperText={telefonHata ? 'Telefon numarası 05XX XXX XXXX formatında olmalıdır.' : ''}
          margin="normal"
        />

        <TextField fullWidth label="Email" value={email} onChange={(e) => setEmail(e.target.value)} margin="normal" />
          <TextField fullWidth label="Şifre" value={sifre} onChange={(e) => setSifre(e.target.value)} margin="normal" type="password" />
          <Button onClick={handleEkle} variant="contained" color="primary">
            Ekle
          </Button>
       
        </Grid> 

        <Grid item xs={9}>
        <TextField
          fullWidth
          label="Ara..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          margin="normal"
        />

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                <TableCell>Adı</TableCell>
                <TableCell>Soyadı</TableCell>
                <TableCell>Telefon</TableCell>
                <TableCell>E-posta</TableCell>
                <TableCell>Şehir</TableCell>
                <TableCell>İlçe</TableCell>
               
                 <TableCell>İşlemler</TableCell>
                </TableRow>
              </TableHead>   
              <TableBody>
              {filteredOgretmenListesi.map((ogretmen) => (
                <TableRow key={ogretmen._id}>
                  <TableCell>{ogretmen.ad}</TableCell>
                  <TableCell>{ogretmen.soyad}</TableCell>
                  <TableCell>{ogretmen.telefon}</TableCell>
                  <TableCell>{ogretmen.email}</TableCell>
                  <TableCell>{ogretmen.sehirAdi}</TableCell>
                  <TableCell>{ogretmen.ilceAdi}</TableCell>
                  <TableCell align="right">
                    <Stack direction="row" spacing={1}>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<EditIcon />}
                        onClick={() => handleEdit(ogretmen._id)}
                      >
                        Düzenle
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<DeleteIcon />}
                        onClick={() => handleDeleteOgretmen(ogretmen._id)}
                        >
                        Sil
                      </Button>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>

            </Table>
          </TableContainer>
        </Grid>
        </Grid>
        <>
        <ResultDialog
         open={eklemeBasariliDialogOpen}
         onClose={() => setEklemeBasariliDialogOpen(false)}
         title={dialogSeverity === 'error' ? "Hata Oluştu" : "İşlem Başarılı"}
         message={dialogContent}
         severity={dialogSeverity}
          />
        <ResultDialog
        open={resultDialogOpen}
        onClose={handleDialogClose}
        title={"İşlem Sonucu"}
        message={resultDialogMessage}
        severity={resultDialogSeverity}
      />

    {renderDuzenlemeFormuDialog()}
      </>
</Paper>

);


}

export default Ogretmen;