import React, { useState, useEffect } from 'react';


const GameTimer = ({ isPlaying }) => {
    const [secondsElapsed, setSecondsElapsed] = useState(0);
  
    useEffect(() => {
      let interval;
      if (isPlaying) {
        interval = setInterval(() => {
          setSecondsElapsed(prevSeconds => prevSeconds + 1);
        }, 1000);
      } else {
        clearInterval(interval);
      }
  
      return () => clearInterval(interval);
    }, [isPlaying]);
  
    const formatTime = (seconds) => {
      const hours = Math.floor(seconds / 3600).toString().padStart(2, '0');
      const minutes = Math.floor((seconds % 3600) / 60).toString().padStart(2, '0');
      const remainingSeconds = (seconds % 60).toString().padStart(2, '0'); 
      return `${hours}:${minutes}:${remainingSeconds}`;
    };
  
    return (
      <div className="game-timer" style={{ textAlign: 'center', marginTop: '20px' }}>
        <div style={{ fontSize: '24px', fontFamily: 'Monospace', background: '#000', color: '#0F0', padding: '10px', display: 'inline-block', borderRadius: '8px' }}>
          {formatTime(secondsElapsed)}
        </div>
      </div>
    );
  };
  
  export default GameTimer;
  