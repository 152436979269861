import React, { useRef, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, } from '@fortawesome/free-solid-svg-icons';
import Header from '../../home/components/Header';
import Footer from '../../home/components/Footer';
import GameTimer from '../components/Egzersizler/Timer';


const canvasWidth = 900;
const canvasHeight = 600;
const colors = ['blue', 'green', 'red', 'yellow', 'purple']; 

function drawShape(ctx, shape) {
  ctx.clearRect(0, 0, canvasWidth, canvasHeight); 
  ctx.fillStyle = shape.color; 

  switch (shape.type) {
    case 'circle':
      ctx.beginPath();
      ctx.arc(shape.x, shape.y, 50, 0, 2 * Math.PI);
      ctx.fill();
      break;
    case 'square':
      ctx.fillRect(shape.x - 50, shape.y - 50, 100, 100);
      break;
    case 'triangle':
      ctx.beginPath();
      ctx.moveTo(shape.x, shape.y - 50); // Üçgenin tepe noktası
      ctx.lineTo(shape.x - 50, shape.y + 50); // Sol alt köşe
      ctx.lineTo(shape.x + 50, shape.y + 50); // Sağ alt köşe
      ctx.closePath();
      ctx.fill();
      break;
    case 'rectangle':
      ctx.fillRect(shape.x - 50, shape.y - 25, 100, 50); // Dikdörtgen çiz
      break;
    default:   
      break;
  }
}

function SekilAvi() {
  const canvasRef = useRef(null);
  const [shape, setShape] = useState({});
  const [isPlaying, setIsPlaying] = useState(false);
  const [speed, setSpeed] = useState(6); 

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const shapes = ['circle', 'square', 'triangle', 'rectangle']; 
    
    const generateShape = () => {
      const type = shapes[Math.floor(Math.random() * shapes.length)];
      const color = colors[Math.floor(Math.random() * colors.length)]; 
      const x = Math.random() * (canvasWidth - 100) + 50;
      const y = Math.random() * (canvasHeight - 100) + 50;
      setShape({ type, x, y, color });
    };

    drawShape(ctx, shape);

    let timer;
    if (isPlaying) {
      timer = setInterval(generateShape, 2000 / speed);
    }

    return () => clearInterval(timer);
  }, [shape, isPlaying]);

  const handleCanvasClick = (e) => {
    const rect = canvasRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;   
  
    let isInside = false;
    switch (shape.type) {
      case 'circle':
        const distance = Math.sqrt((x - shape.x) ** 2 + (y - shape.y) ** 2);
        isInside = distance <= 50; 
        break;
      case 'square':
        isInside = x >= (shape.x - 50) && x <= (shape.x + 50) && y >= (shape.y - 50) && y <= (shape.y + 50);
        break;
      default:
        break;
    }

    if (isInside) {
      alert('Tebrikler, doğru şekli buldunuz!');
 
    }
  };

  return (
    <div>
        <Header />
        <div className="container">
        <div className="canvas-container">
        <div className="clock-timer-container">
        <GameTimer className="game-timer" isPlaying={isPlaying} />
        </div>
        <button className="play-button" onClick={() => setIsPlaying(!isPlaying)}>
          <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
        </button>
        <div className="speed-controls">         
            <input type="range" value={speed} onChange={(e) => setSpeed(Math.max(1, parseInt(e.target.value)))} min="1" max="20" />
            <span> Hız: {speed} </span>
          </div>
    <canvas ref={canvasRef} width={canvasWidth} height={canvasHeight} onClick={handleCanvasClick} />
  </div>

     
      </div>
  <Footer />
    </div>
  
  );
}

export default SekilAvi;
