import React from 'react';
import { Container, Typography, Box, Stack, Paper, useTheme } from '@mui/material';
import Header from '../home/components/Header';
import Footer from '../home/components/Footer';
import { useNavigate } from 'react-router-dom';
import StarIcon from '@mui/icons-material/Star'; 
import VisibilityIcon from '@mui/icons-material/Visibility'; 
import TimelineIcon from '@mui/icons-material/Timeline'; 
import LensIcon from '@mui/icons-material/Lens'; 
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'; 
import '../assets/Egzersiz.css';


function Egzersiz() {
  const theme = useTheme();
  const navigate = useNavigate(); 

  const menuItems = [
    {
      title: 'Nesneleri Takip Et',
      description: 'Gözün sayfa üzerindeki hareketini hızlandırmak ve göz kaslarını esnetmek.',
      icon: <StarIcon />,
      path: '/nesne-takip'
    },
    {
      title: 'Şekil Avı',
      description: 'Bu oyun, kullanıcıların ekranda kısa süreliğine gösterilen çeşitli geometrik şekiller arasından belirli hedef şekilleri tanıyıp seçmelerini gerektirir.',
      icon: <VisibilityIcon />,
      path: '/sekil-avi'
    },
    {
      title: 'Zigzag',
      description: 'Zigzag göz takibi egzersizi, göz koordinasyonunu ve odaklanma yeteneğini artırarak göz yorgunluğunu azaltır.',
      icon: <TimelineIcon />,
      path: '/zigzag'
    },
    {
      title: 'Noktayı Takip Et',
      description: "Bu oyun, mouse'u takip ederek gözlerin odaklanma ve izleme yeteneklerini geliştirmeye yardımcı olur",
      icon: <LensIcon />,
      path: '/nokta-takip'
    },
    {
      title: 'Kelime Tahmin Oyunu',
      description: "Bu oyun, kelime tanıma hızını ve dikkat süresini artırarak hızlı okuma ve anlamayı destekler.",
      icon: <HelpOutlineIcon />,
      path: '/kelime-tahmin'
    },
    {
      title: 'Harf Döngüsü',
      description: "Harf Döngüsü, hızlı düşünmeyi ve kelime becerilerini geliştirmek için tasarlanmış, dinamik bir dikkat oyunudur.",
      icon: <HelpOutlineIcon />,
      path: '/harf-dongusu'
    },
  ];
  

  const handlePaperClick = (path) => {
    navigate(path); 
  };
  
  return (
    <div>
      <Header />
      <div  className='bg-main'>
      <Container
        maxWidth="md"
        className='main-container'
        sx={{
          mt: 4,
          mb: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          bgcolor: 'background.default',        
          borderRadius: '12px',
          p: 4,  
          color: theme.palette.common.white,
         
        }}
      >
        <Typography variant="h4" component="h1"  sx={{          
          mt:15,
          mb:4
        }} gutterBottom>
          Egzersizler Kısmı
        </Typography>     
        <Stack 
          direction="column" 
          spacing={0} 
          sx={{ 
            width: '100%',
            maxWidth: '100%',
            '.MuiPaper-root': {
           
              '&:not(:last-child)': {
                borderBottom: 0,
              },
              overflow: 'auto', 
              maxHeight: '75vh', 
            },        
            
          }}
        >
  {menuItems.map((item, index) => (
  <Paper 
    key={index} 
    elevation={4} 
    onClick={() => handlePaperClick(item.path)}         
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      p: 3,
      m: 0.1, 
      transition: 'transform 0.3s, box-shadow 0.3s',
      '&:hover': {
        transform: 'translateX(10px)',
        boxShadow: '0 8px 24px rgba(0,0,0,0.6)',
      },
      bgcolor: theme.palette.primary.main,
      backgroundImage: `linear-gradient(145deg, ${theme.palette.primary.main}, #64748B)`, 
      color: theme.palette.primary.contrastText,
      borderRadius: '20px', 
      boxShadow: '0 8px 24px rgba(0,0,0,0.3)',
   
    }}
  >
    <Box sx={{ display: 'flex', alignItems: 'center', paddingRight:'35px'}}>
      {item.icon} 
      <Typography variant="h6" sx={{ ml: 2 }}>{item.title}</Typography>
    </Box>

    <Typography variant="body2" sx={{ flexGrow: 2, ml: 2, fontSize: '1rem' }}>{item.description}</Typography>

  </Paper>
))}

        </Stack>

      </Container>
      </div>
      <Footer />
    </div>
  );
}

export default Egzersiz;
