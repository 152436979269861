import React from 'react';
import {  Container,  Typography,    Box,  Stack,  Paper,  useTheme, } from '@mui/material';
import Header from '../home/components/Header';
import Footer from '../home/components/Footer';
import '../assets/HizliOku.css';

function HizliOku() {
  const theme = useTheme();

  const menuItems = [
    {
      title: 'Akan Yazılar',
      description: 'Sıra ile gelen kelime veya kelime gruplarına ayak uydurun. Bu çalışma okumanıza tempo kazandıracaktır.',
    },
    {
      title: 'Silinen Yazılar',
      description: 'Sıra ile silinen kelime veya kelime gruplarına ayak uydurun. Bu çalışma okumanıza tempo kazandıracaktır.',
    },
    {
      title: 'Boyanan Yazılar',
      description: 'Sıra ile boyanan kelime veya kelime gruplarına ayak uydurun. Bu çalışma okumanıza tempo kazandıracaktır.',
    },
    {
      title: 'Vurgulanan Yazılar',
      description: 'Vurgulanan kelime veya kelime gruplarına ayak uydurun. Bu çalışma okumanıza tempo kazandıracaktır.',
    },
    {
      title: 'Vurgulanan Yazılar-2',
      description: 'Vurgulanan kelime veya kelime gruplarına ayak uydurun. Bu çalışma okumanıza tempo kazandıracaktır.',
    },

    {
      title: 'Kayan Kelimeler',
      description: 'Ekranda beliren kelime veya kelime gruplarına ayak uydurun. Bu çalışma okumanıza tempo kazandıracaktır.',
    },
    {
      title: 'Görünen Kelimeler',
      description: 'Ekranda beliren kelime veya kelime gruplarına ayak uydurun. Bu çalışma okumanıza tempo kazandıracaktır.',
    },
    {
      title: 'Takistoskopik Okuma',
      description: 'Ekranda beliren kelime veya kelime gruplarına ayak uydurun. Bu çalışma okumanıza tempo kazandıracaktır.',
    },
    {
      title: 'Rastgele Kelimeler',
      description: 'Ekranda beliren kelime veya kelime gruplarına ayak uydurun. Bu çalışma okumanıza tempo kazandıracaktır.',
    },
    {
      title: 'Sayfa Tarama',
      description: 'Bu çalışma sayfa tarama hızınızı arttıracaktır. Vurgulanan kelime veya kelime gruplarına ayak uydurun.',
    },
  
  ];
  

  return (
    <div>
      <Header />
      <div  className='bg-main'>
      <Container
        maxWidth="md"
        className='main-container'
        sx={{
          mt: 4,
          mb: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          bgcolor: 'background.default',        
          borderRadius: '12px',         
          color: theme.palette.common.white,    
         
        }}
      >
        <Typography variant="h4" component="h1"
         sx={{          
          mt:75,
          mb:4        
        
        }}
        gutterBottom>
          Hızlı Oku Uygulaması
        </Typography>
      
        <Stack 
          direction="column" 
          spacing={0} 
          sx={{
            width: '100%',
            maxWidth: '100%',
            
            mb:4
          
          
          }}
        >
          {menuItems.map((item, index) => (
           <Paper 
           key={index} 
           elevation={4} 
           sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: 3,
            m: 0.1, 
            transition: 'transform 0.3s, box-shadow 0.3s',
            '&:hover': {
              transform: 'translateX(10px)',
              boxShadow: '0 8px 24px rgba(0,0,0,0.6)',
            },
            bgcolor: theme.palette.primary.main,
            backgroundImage: `linear-gradient(145deg, ${theme.palette.primary.main}, #64748B)`, 
            color: theme.palette.primary.contrastText,
            borderRadius: '20px', 
            boxShadow: '0 8px 24px rgba(0,0,0,0.3)',
         
          }}
         >
               <Typography variant="h6" sx={{ flexGrow: 1 }}>{item.title}</Typography>
        

            <Typography variant="body2" sx={{ flexGrow: 2, ml: 2 }}>{item.description}</Typography>
          
            </Paper>
          ))}
        </Stack>
   
     
      </Container>
      </div>
      <Footer />
    </div>
  );
}

export default HizliOku;
