import React, { useState, useEffect } from 'react';
import Header from '../../home/components/Header';
import Footer from '../../home/components/Footer';



function Modal({ isOpen, onClose, title, children }) {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>&times;</button>
        {title && <h2>{title}</h2>}
        {children}
      </div>
    </div>
  );
}

function WordGuessingGame() {
  const [targetWord, setTargetWord] = useState('');
  const [displayWord, setDisplayWord] = useState('');
  const [wrongGuesses, setWrongGuesses] = useState([]);
  const [activeLetter, setActiveLetter] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');


  const showModal = (content) => {
    setModalContent(content);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
   
  };

  useEffect(() => {
    // Bu kısım daha sonra dinamik olarak çekilecek
    const wordList = ['elma', 'muz', 'portakal', 'kivi', 'kiraz'];
    const randomIndex = Math.floor(Math.random() * wordList.length);
    const word = wordList[randomIndex];
    setTargetWord(word);
    setDisplayWord('_'.repeat(word.length));
  }, []);

 
  const handleGuess = (letter) => {
    setActiveLetter(letter); // Aktif harfi ayarla
    let isCorrectGuess = false;
    let newDisplayWord = '';

    for (let i = 0; i < targetWord.length; i++) {
      if (targetWord[i] === letter) {
        newDisplayWord += letter;
        isCorrectGuess = true;
      } else {
        newDisplayWord += displayWord[i];
      }
    }

    if (!isCorrectGuess) {
      setWrongGuesses([...wrongGuesses, letter]);
    } else {
     
      setTimeout(() => {
        setActiveLetter(''); 
      }, 1000); 
    }

    setDisplayWord(newDisplayWord);

    if (newDisplayWord === targetWord) {
      showModal(<span>Tebrikler, kazandınız!</span>);
    } else if (wrongGuesses.length > 5) {
      showModal(
        <div>
          <span>Maalesef, kaybettiniz!</span>
          <hr style={{ border: '1px solid #f00', width: '100%', marginTop: '8px', marginBottom: '8px' }} />
          <span style={{ color: '#f00', fontWeight: 'bold', textTransform: 'uppercase' }}>
            Doğru kelime: {targetWord}
          </span>
        </div>
      );
    }
    
    


  };

  
  return (
   <div>
      <Header />
      <div className="container">
        <div className="canvas-container">
          <p className="display-word">Kelime: {displayWord.split('').join(' ')}</p>
          <p className="wrong-guesses">Yanlış Tahminler: {wrongGuesses.join(', ')}</p>
          <div className="button-container">
            {'abcçdefgğhıijklmnoöpqrsştuüvwxyz'.split('').map((letter) => (
              <button key={letter} onClick={() => handleGuess(letter)} className="letter-button">
                {letter.toUpperCase()}
              </button>
            ))}
          </div>

    
      <Modal 
        isOpen={isModalOpen} 
        onClose={() => setIsModalOpen(false)}
        title="Kaybettiniz"
      >
       <p>{modalContent}</p>
      </Modal>
        </div>     
      </div>

     
      <Footer />
    </div>
  );
}

export default WordGuessingGame;
