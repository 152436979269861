import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Button, Drawer, List, ListItem, ListItemIcon, ListItemText, useMediaQuery, Box,  useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom';
import { createTheme, ThemeProvider, styled, alpha } from '@mui/material/styles';
import BookIcon from '@mui/icons-material/Book';
import LoginIcon from '@mui/icons-material/Login';
import PersonAddIcon from '@mui/icons-material/PersonAdd';


const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: 'linear-gradient(45deg, #0A192F 0%, #203A43 25%, #2C5364 50%, #47688E 75%, #6F9AB8 100%)',
  backdropFilter: 'blur(10px)',
  backgroundSize: '200% 200%',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.9)',
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: 10,
    left: 0,
    width: '100%',
    height: '20px',
    backgroundImage: 'linear-gradient(135deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 50%, rgba(0,0,0,0) 100%)',
    zIndex: 1,
  },
  animation: 'gradient 15s ease infinite',
  '& .MuiToolbar-root': {
    minHeight: '100px',
  },
}));


const AnimatedButton = styled(Button)(({ theme }) => ({
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.1)',
    boxShadow: `0 4px 20px 0 ${alpha(theme.palette.primary.main, 0.7)}`, 
  },
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(1),
  margin: theme.spacing(1),
  fontSize: '1rem',
 fontWeight: 'bold',
  width: '140px', 
  height: 'auto',
  border: `3px solid ${alpha(theme.palette.primary.main, 0.1)}`, 
  boxShadow: '0 3px 5px 2px rgba(0, 0, 0, 0.1)', 
}));


function Header() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <ThemeProvider theme={theme}>
      <StyledAppBar position="static">
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            Hızlı Okuma ve Anlama
          </Typography>
          {isMobile ? (
            <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerToggle}>
              <List>
                <ListItem button component={Link} to="/home">
                  <ListItemIcon><HomeIcon /></ListItemIcon>
                  <ListItemText primary="Anasayfa" />
                </ListItem>
                <ListItem button component={Link} to="/hizlioku">
                  <ListItemIcon><BookIcon /></ListItemIcon>
                  <ListItemText primary="Hızlı Oku" />
                </ListItem>
                <ListItem button component={Link} to="/egsersizler">
                  <ListItemIcon><BookIcon /></ListItemIcon>
                  <ListItemText primary="Egzersizler" />
                </ListItem>
                <ListItem button component={Link} to="/testler">
                  <ListItemIcon><BookIcon /></ListItemIcon>
                  <ListItemText primary="Test Yap" />
                </ListItem>
                <ListItem button component={Link} to="/login">
                  <ListItemIcon><LoginIcon /></ListItemIcon>
                  <ListItemText primary=" Giriş Yap" />
                </ListItem>
                <ListItem button component={Link} to="/register">
                  <ListItemIcon><PersonAddIcon /></ListItemIcon>
                  <ListItemText primary="Kayıt Ol" />
                </ListItem>
              </List>
            </Drawer>
          ) : (
            <div>
             <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <AnimatedButton color="inherit" component={Link} to="/home" startIcon={<HomeIcon sx={{ marginBottom: 1 }} />}>
          Anasayfa
        </AnimatedButton>
        <AnimatedButton color="inherit" component={Link} to="/hizlioku" startIcon={<BookIcon sx={{ fontSize: '3rem', marginBottom: 1 }} />}>
          Hızlı Oku
        </AnimatedButton>
        <AnimatedButton color="inherit" component={Link} to="/egsersizler" startIcon={<BookIcon sx={{ fontSize: '3rem', marginBottom: 1 }} />}>
         Egzersizler
        </AnimatedButton>
        <AnimatedButton color="inherit" component={Link} to="/testler" startIcon={<BookIcon sx={{ fontSize: '3rem', marginBottom: 1 }} />}>
         Test Yap
        </AnimatedButton>
       
        <AnimatedButton color="inherit" component={Link} to="/login" startIcon={<LoginIcon sx={{ fontSize: '3rem', marginBottom: 1 }} />}>
          Giriş Yap
        </AnimatedButton>
        <AnimatedButton color="inherit" component={Link} to="/register" startIcon={<PersonAddIcon sx={{ fontSize: '3rem', marginBottom: 1 }} />}>
          Kayıt Ol
        </AnimatedButton>         
        </Box> 
          </div>          
          )}
        </Toolbar>
      </StyledAppBar>
    </ThemeProvider>
  );
}

export default Header;
