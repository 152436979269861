import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Typography, Drawer, List, ListItem, ListItemIcon, ListItemText, CssBaseline } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import MailIcon from '@mui/icons-material/Mail';
import SettingsIcon from '@mui/icons-material/Settings'; 
import LogoutIcon from '@mui/icons-material/Logout'; 
import { API_URLS } from '../../config/config';

function OgrenciDashboard() {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate(); 


  const drawerWidth = 240;
  const appBarStyle = {
    width: `calc(100% - ${drawerOpen ? drawerWidth : 0}px)`,
    transition: 'width 0.3s',
  };
  const drawerStyle = {
    width: drawerWidth,
    flexShrink: 0,
  };
  const drawerPaperStyle = {
    width: drawerWidth,
  };

  const contentStyle = {
    flexGrow: 1,
    padding: '24px',
    transition: 'margin-left 0.3s',
    marginLeft: `${drawerOpen ? drawerWidth : 0}px`,
    marginTop: '64px', 
  };
  

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen); 
  };


  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('userToken');
      if (!token) {      
        navigate('/ogrenci-giris');
      } else {
        try {
          const response = await fetch(API_URLS.STUDENT_URL + 'me', {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
          if (response.ok) {
            const data = await response.json();
            setUserData(data);
           
           
          } else {
       throw new Error('Veri alınırken bir hata oluştu');
    
          }
        } catch (error) {
          console.error(error);
          navigate('/ogrenci-giris');
        }
      }
    };

    fetchUserData();
  }, [navigate]); 

  const handleLogout = async () => {
    try {
      
      await fetch(API_URLS.STUDENT_URL + 'logout', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('userToken')}`,
          'Content-Type': 'application/json',
        },
      });
       localStorage.removeItem('userToken');
      navigate('/ogrenci-giris');
    } catch (error) {
      console.error('Çıkış işlemi sırasında bir hata oluştu', error);
    }
  };

  const menuItems = [
    { text: 'Dashboard', icon: <DashboardIcon /> },
    { text: 'Mail', icon: <MailIcon /> }, 
    { text: 'Ayarlar', icon: <SettingsIcon /> }, 
    { text: 'Çıkış Yap', icon: <LogoutIcon />, action: handleLogout },
   
  ];

  
  return (
    <div style={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" style={appBarStyle}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={toggleDrawer}
            style={{ marginRight: '20px', ...(drawerOpen && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Öğrenci Paneli
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="persistent"
        open={drawerOpen}
        style={drawerStyle}
        sx={{ '& .MuiDrawer-paper': drawerPaperStyle }}
      >
     <List>
          {menuItems.map((item, index) => (
            <ListItem button key={index} onClick={() => item.action()}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <main style={contentStyle}>
        <Typography paragraph>
          Ana içerik buraya gelecek. Dashboard'unuzun ana sayfasını özelleştirebilirsiniz.
        </Typography>
        <Typography paragraph>
          Hoş Geldiniz, {userData && userData.ad}
        </Typography>
      </main>
    </div>
  );
}

export default OgrenciDashboard;
