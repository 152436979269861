import React, { useState, useEffect } from 'react';
import {IconButton, Button, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,  Grid, TextField, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { API_URLS } from '../../../config/config';
import ResultDialog from '../../../pages/components/Auth/Dialog';


const Okullar = () => {
  const [okulListesi, setOkulListesi] = useState([]);
  const [sehirListesi, setSehirListesi] = useState([]);  
  const [secilenSehirId, setSecilenSehirId] = useState('');
  const [secilenIlceId, setSecilenIlceId] = useState("");
  const [shouldFetch, setShouldFetch] = useState(true);

  const [okulAdi, setOkulAdi] = useState('');
  const [telefon, setTelefon] = useState('');
  const [telefonHata, setTelefonHata] = useState(false);
  const [secilenKurumTurleri, setSecilenKurumTurleri] = useState([]);
  const [email, setEmail] = useState('');
  const [sifre, setSifre] = useState('');

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');
  const [dialogSeverity, setDialogSeverity] = useState('info');
  const [eklemeBasariliDialogOpen, setEklemeBasariliDialogOpen] = useState(false); 
  const [silmeBasariliDialogOpen, setSilmeBasariliDialogOpen] = useState(false); 
  const [duzenlemeDialogAcik, setDuzenlemeDialogAcik] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const filteredOkulListesi = okulListesi.filter(kurum =>
    kurum.okul_adi.toLowerCase().includes(searchQuery.toLowerCase()) ||
    kurum.email.toLowerCase().includes(searchQuery.toLowerCase())
  );
  
 

  const [duzenlenenKurum, setDuzenlenenKurum] = useState({
    okulAdi: '',
    email: '',
    telefon: '',
    sehirId: '',
    ilceId: '',
    kurumTuru: [],
    ilceler: [],
  });

  const fetchIlceler = async (sehirId, seciliIlceId) => {
    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}sehirler/ilceler/${sehirId}`);
      if (!response.ok) throw new Error('İlçeler yüklenirken bir hata oluştu.');
      const data = await response.json(); 
      const ilceler = data.ilceler ? data.ilceler : []; 
  
      setDuzenlenenKurum(prev => ({ 
        ...prev, 
        ilceler: ilceler,
     
        ilceId: seciliIlceId && ilceler.some(ilce => ilce._id === seciliIlceId) ? seciliIlceId : prev.ilceId 
      }));
    } catch (error) {
      console.error('İlçeleri yüklerken bir hata oluştu:', error);
    }
  };  

  useEffect(() => {
    const fetchSehirler = async () => {
      if (!shouldFetch) return;
  
      try {
        const response = await fetch(API_URLS.ADMIN_URL + "sehirler");
        if (!response.ok) {
          throw new Error('Veri çekme işlemi başarısız');
        }
        const sehirler = await response.json();    
        setSehirListesi(sehirler);

      } catch (err) {
        console.error("Şehirler yüklenirken bir hata oluştu:", err.message);
      } finally {
        setShouldFetch(false);
      }
    };

    const fetchSchools = async () => {
      try {
        const response = await fetch(API_URLS.ADMIN_URL + "schools");
        if (!response.ok) throw new Error('Kurumlar yüklenirken bir hata oluştu.');
        const data = await response.json();
        setOkulListesi(data);
      } catch (error) {
        console.error(error.message);    
      }
    };  

    fetchSchools();
    fetchSehirler();

  });

  const handleSehirChange = (event) => {
    setSecilenSehirId(event.target.value);
  };

  const handleIlceChange = (event) => {
    setSecilenIlceId(event.target.value);
  };

  const handleTelefonChange = (event) => {
    const telefonRegex = /^0(\d{3}) (\d{3}) (\d{4})$/;
    let { value } = event.target;
    const numbers = value.replace(/\D/g, '');  
    value = numbers.substring(0, 4) + (numbers.length > 4 ? ' ' : '') + numbers.substring(4, 7) + (numbers.length > 7 ? ' ' : '') + numbers.substring(7, 11); 
    setTelefonHata(!telefonRegex.test(value) && value !== '');
    setTelefon(value);
  }; 

  const kurumTurleri = [
    { id: 'ilkokul', adi: 'İlkokul' },
    { id: 'ortaokul', adi: 'Ortaokul' },
    { id: 'lise', adi: 'Lise' },
    { id: 'kurs', adi: 'Kurs' }
  ];

  const secilenSehirinIlceleri = sehirListesi.find(sehir => sehir._id === secilenSehirId)?.ilceler || [];


  const handleEkle = async () => {
    let eksikAlanlar = [];
  
    if (!okulAdi) eksikAlanlar.push('Okul Adı');
    if (!email) eksikAlanlar.push('Email');
    if (!sifre) eksikAlanlar.push('Şifre');
    if (!telefon) eksikAlanlar.push('Telefon');
    if (!secilenSehirId) eksikAlanlar.push('Şehir');
    if (!secilenIlceId) eksikAlanlar.push('İlçe');
    if (secilenKurumTurleri.length === 0) eksikAlanlar.push('Kurum Türleri');
  
    if (eksikAlanlar.length > 0) {
      setDialogSeverity('error');
      setDialogContent(`Lütfen ${eksikAlanlar.join(', ')} alanlarını doldurunuz.`);
      setEklemeBasariliDialogOpen(true);
      return;
    }
  
 
    if (telefonHata) {
      setDialogSeverity('error');
      setDialogContent('Lütfen geçerli bir telefon numarası giriniz.');
      setEklemeBasariliDialogOpen(true);
      return;
    }

    try {  
      const response = await fetch(API_URLS.ADMIN_URL + 'schools', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          okul_adi: okulAdi,
          email: email,
          password : sifre,
          telefon : telefon,
          sehir: secilenSehirId,
          ilce: secilenIlceId,
          kurum_turu: secilenKurumTurleri,

        }),
      });

      const responseData = await response.json();

      if (response.ok) {
        setOkulAdi([...okulListesi, responseData]); 
        
        setDialogSeverity('success');
        setDialogContent('Yeni okul başarıyla eklendi.');
        setEklemeBasariliDialogOpen(true);         

        setOkulAdi('');
        setEmail('');
        setSifre('');     
        setTelefon('');
        setSecilenSehirId('');
        setSecilenIlceId('');
        setSecilenKurumTurleri([]);
      } else {
       
        throw new Error(responseData.message || 'Bir hata oluştu');
      }
    } catch (err) {
      setDialogSeverity('error');
      setDialogContent(err.message);
      setEklemeBasariliDialogOpen(true); 
    }
  };

  const handleSehirChangeDuzenle = (event) => {
    const yeniSehirId = event.target.value; 
    setDuzenlenenKurum(prev => ({ ...prev, sehirId: yeniSehirId }));  
    const secilenSehir = sehirListesi.find(sehir => sehir._id === yeniSehirId);
    const ilceler = secilenSehir ? secilenSehir.ilceler : [];  
    setDuzenlenenKurum(prev => ({ ...prev, ilceId: '', ilceler: ilceler }));
  };  

  const handleIlceChangeDuzenle = (event) => {
    const yeniIlceId = event.target.value; 
    setDuzenlenenKurum(prev => ({ ...prev, ilceId: yeniIlceId }));
  };

  const handleEdit = (kurumId) => {
    const seciliKurum = okulListesi.find(kurum => kurum._id === kurumId);

    if (seciliKurum) {
 
      const sehirId = seciliKurum.sehir;

      setDuzenlenenKurum(prev => ({
        ...prev,
        _id: seciliKurum._id,
        okulAdi: seciliKurum.okul_adi,
        email: seciliKurum.email,
        telefon: seciliKurum.telefon,
        sehirId: sehirId, 
        ilceId: seciliKurum.ilce, 
        kurumTuru: seciliKurum.kurum_turu,
      }));  
      fetchIlceler(sehirId);   
      setDuzenlemeDialogAcik(true);
    } else {
      console.error('Seçilen kurum bulunamadı.');
    }
  }; 

  const handleGuncelle = async () => {
    // Validation işlemleri
    let eksikAlanlar = [];
  
    if (!duzenlenenKurum.okulAdi) eksikAlanlar.push('Okul Adı');
    if (!duzenlenenKurum.email) eksikAlanlar.push('Email');
    if (!duzenlenenKurum.telefon) eksikAlanlar.push('Telefon');
    if (!duzenlenenKurum.sehirId) eksikAlanlar.push('Şehir');
    if (!duzenlenenKurum.ilceId) eksikAlanlar.push('İlçe');
    if (duzenlenenKurum.kurumTuru.length === 0) eksikAlanlar.push('Kurum Türleri');
  
    if (eksikAlanlar.length > 0) {
      setDialogSeverity('error');
      setDialogContent(`Lütfen ${eksikAlanlar.join(', ')} alanlarını doldurunuz.`);
      setDialogOpen(true); 
      return;
    }
  
    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}schools/${duzenlenenKurum._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          okul_adi: duzenlenenKurum.okulAdi,
          email: duzenlenenKurum.email,
          telefon: duzenlenenKurum.telefon,
          sehir: duzenlenenKurum.sehirId,
          ilce: duzenlenenKurum.ilceId,
          kurum_turu: duzenlenenKurum.kurumTuru,
        }),
      });
  
      const data = await response.json();  
      if (response.ok) {
        setOkulListesi(okulListesi.map(kurum => kurum._id === duzenlenenKurum._id ? data : kurum));
        setDialogSeverity('success');
        setDialogContent('Kurum başarıyla güncellendi.');
        setDialogOpen(true);
        setDuzenlemeDialogAcik(false); 
      } else {
        throw new Error(data.message || 'Bir hata oluştu');
      }
    } catch (err) {
      setDialogSeverity('error');
      setDialogContent(err.message);
      setDialogOpen(true); 
    }
  };

  const handleDelete = async (kurumId) => {
    const onay = window.confirm('Bu okulu silmek istediğinize emin misiniz?');
    if (!onay) return;
  
    try {
      const response = await fetch(API_URLS.ADMIN_URL + `schools/${kurumId}`, {
        method: 'DELETE',
      });
  
      const data = await response.json();
  
      if (!response.ok) {
        setDialogSeverity('error');
        setDialogContent(data.message || 'Kurum silinirken bir hata oluştu.');
        setSilmeBasariliDialogOpen(true); 
      } else {
      
        setDialogSeverity('success');
        setDialogContent(`${data.message}`);
        setSilmeBasariliDialogOpen(true); 
      }
    } catch (err) {
      setDialogSeverity('error');
      setDialogContent('Hata: ' + err.message);
      setSilmeBasariliDialogOpen(true); 
    }
  };

  const renderDuzenlemeFormuDialog = () => (
    <Dialog open={duzenlemeDialogAcik} onClose={() => setDuzenlemeDialogAcik(false)}>
      <DialogTitle>Kurum Düzenle</DialogTitle>
      <DialogContent>    
        <TextField
          autoFocus
          margin="dense"
          id="okulAdi"
          label="Okul Adı"
          type="text"
          fullWidth
          variant="outlined"
          value={duzenlenenKurum.okulAdi}
          onChange={(e) => setDuzenlenenKurum({...duzenlenenKurum, okulAdi: e.target.value})}
        />
        {/* Email */}
        <TextField
          margin="dense"
          id="email"
          label="Email"
          type="email"
          fullWidth
          variant="outlined"
          value={duzenlenenKurum.email}
          onChange={(e) => setDuzenlenenKurum({...duzenlenenKurum, email: e.target.value})}
        />
        {/* Telefon */}
        <TextField
          margin="dense"
          id="telefon"
          label="Telefon"
          type="tel"
          fullWidth
          variant="outlined"
          value={duzenlenenKurum.telefon}
          onChange={(e) => setDuzenlenenKurum({...duzenlenenKurum, telefon: e.target.value})}
        />
     
     <FormControl fullWidth margin="dense">
          <InputLabel id="sehir-select-label-duzenle">Şehir</InputLabel>
          <Select
            labelId="sehir-select-label-duzenle"
            id="sehir-select-duzenle"
            value={duzenlenenKurum.sehirId}
            onChange={handleSehirChangeDuzenle}
            displayEmpty 
          >
            <MenuItem value="">
              <em>Şehir Seçiniz</em>
            </MenuItem>
            {sehirListesi.map((sehir) => (
              <MenuItem key={sehir._id} value={sehir._id}>
                {sehir.sehir_adi}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {duzenlenenKurum.sehirId && (
          <FormControl fullWidth margin="dense">
            <InputLabel id="ilce-select-label-duzenle">İlçe</InputLabel>
            <Select
              labelId="ilce-select-label-duzenle"
              id="ilce-select-duzenle"
              value={duzenlenenKurum.ilceId} 
              onChange={handleIlceChangeDuzenle}
              displayEmpty
              renderValue={(selected) => {
                if (selected) {
                  const secilenIlce = duzenlenenKurum.ilceler.find(ilce => ilce._id === selected);
                  return secilenIlce ? secilenIlce.ilce_adi : 'İlçe bulunamadı';
                }
                return <em>İlçe Seçiniz</em>;
              }}              
            >
          
              {duzenlenenKurum.ilceler?.map((ilce) => ( 
                <MenuItem key={ilce._id} value={ilce._id}>
                  {ilce.ilce_adi}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <FormControl fullWidth margin="dense">
          <InputLabel id="kurum-turu-label">Kurum Türü</InputLabel>
          <Select
            labelId="kurum-turu-label"
            id="kurum-turu-select"
            multiple
            value={duzenlenenKurum.kurumTuru ? duzenlenenKurum.kurumTuru : []}
            onChange={(event) => {            
              setDuzenlenenKurum((prevDuzenlenenKurum) => ({
                ...prevDuzenlenenKurum,
                kurumTuru: event.target.value,
              }));
            }}
            renderValue={(selected) => selected.join(', ')}
          >
            {kurumTurleri.map((tur) => (
              <MenuItem key={tur.id} value={tur.adi}>
                {tur.adi}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDuzenlemeDialogAcik(false)}>İptal</Button>
        <Button onClick={handleGuncelle}>Güncelle</Button> 

      </DialogActions>
    </Dialog>
  ); 

  return (
    <Paper style={{ padding: 20 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <Typography variant="h5" component="h3" marginBottom={2}>
            Okul Kayıt Formu
          </Typography>
          <FormControl fullWidth margin="normal" >
          <InputLabel id="sehir-select-label">Şehir</InputLabel>
          <Select
            labelId="sehir-select-label"
            value={secilenSehirId}
            onChange={handleSehirChange}
            displayEmpty
            renderValue={(selected) => {
              if (selected === "") {
                return <em>Şehir Seçiniz</em>;
              }             
              const secilenSehir = sehirListesi.find(sehir => sehir._id === selected);
              return secilenSehir ? secilenSehir.sehir_adi : <em>Şehir Seçiniz</em>;
            }}
          >
            <MenuItem disabled value="">
              <em>Şehir Seçiniz</em>
            </MenuItem>
            {sehirListesi.map((sehir) => (
              <MenuItem key={sehir._id} value={sehir._id}>
                {sehir.sehir_adi}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {secilenSehirId && (
        <FormControl fullWidth margin="normal">
          <InputLabel id="ilce-select-label">İlçe</InputLabel>
          <Select
            labelId="ilce-select-label"
            id="ilce-select"
            value={secilenIlceId}
            onChange={handleIlceChange}
            displayEmpty
            renderValue={(selected) => {
              if (!selected) {
                return <em>İlçe Seçiniz</em>;
              }
              const secilenIlce = secilenSehirinIlceleri.find(ilce => ilce._id === selected);
              return secilenIlce ? secilenIlce.ilce_adi : <em>İlçe Seçiniz</em>;
            }}
          >
            <MenuItem disabled value="">
              <em>İlçe Seçiniz</em>
            </MenuItem>
            {secilenSehirinIlceleri.map((ilce) => (
              <MenuItem key={ilce._id} value={ilce._id}>
                {ilce.ilce_adi}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )} 
          <TextField fullWidth label="Okul Adı" value={okulAdi} onChange={(e) => setOkulAdi(e.target.value)} margin="normal" />
          <TextField
          fullWidth
          label="Telefon"
          value={telefon}
          onChange={handleTelefonChange}
          error={telefonHata}
          helperText={telefonHata ? 'Telefon numarası 05XX XXX XXXX formatında olmalıdır.' : ''}
          margin="normal"
        />

      <FormControl fullWidth margin="normal">
            <InputLabel id="kurum-turu-label">Kurum Türü</InputLabel>
            <Select
              labelId="kurum-turu-label"
              id="kurum-turu-select"
              multiple
              value={secilenKurumTurleri}
              onChange={(event) => setSecilenKurumTurleri(event.target.value)}
              renderValue={(selected) => selected.join(', ')}
            >
              {kurumTurleri.map((tur) => (
                <MenuItem key={tur.id} value={tur.adi}>
                  {tur.adi}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField fullWidth label="Email" value={email} onChange={(e) => setEmail(e.target.value)} margin="normal" />
          <TextField fullWidth label="Şifre" value={sifre} onChange={(e) => setSifre(e.target.value)} margin="normal" type="password" />
          <Button onClick={handleEkle} variant="contained" color="primary">
            Ekle
          </Button>
        </Grid>
        <Grid item xs={12} md={9}>
          <Typography variant="h5" component="h3" marginBottom={2}>
            Kayıtlı Okullar Listesi
          </Typography>
          <TextField
          fullWidth
          label="Ara..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          margin="normal"
        />

          <TableContainer component={Paper}>
            <Table aria-label="okul listesi">
              <TableHead>
              <TableRow>
              <TableCell>Okul Adı</TableCell>
              <TableCell align="right">Email</TableCell>
              <TableCell align="right">Telefon</TableCell>
              <TableCell align="right">Şehir</TableCell>
              <TableCell align="right">İlçe</TableCell>
              <TableCell align="right">Okul Türü</TableCell>
              <TableCell align="right">İşlem</TableCell>
             </TableRow>
              </TableHead>
             
           
              <TableBody>
  {filteredOkulListesi.map((kurum, index) => (
    <TableRow key={index}>
      <TableCell component="th" scope="row">
        {kurum.okul_adi}
      </TableCell>
      <TableCell align="right">{kurum.email}</TableCell>
      <TableCell align="right">{kurum.telefon}</TableCell>
      <TableCell align="right">{kurum.sehirAdi}</TableCell>
      <TableCell align="right">{kurum.ilceAdi}</TableCell>
      <TableCell align="right">
        {kurum.kurum_turu ? kurum.kurum_turu.join(', ') : ''}
      </TableCell>
      <TableCell align="right">
        <IconButton onClick={() => handleEdit(kurum._id)}
          variant="outlined"
          color="primary"
          size="small"
          startIcon={<EditIcon />}
          sx={{
            mr: 1, 
            '&:hover': {
              backgroundColor: 'lightblue', 
              color: '#fff', 
            }
          }}
        >
          <EditIcon />
        </IconButton>
        <IconButton onClick={() => handleDelete(kurum._id)}
          variant="contained"
          color="secondary"
          size="small"
          startIcon={<DeleteIcon />}
          sx={{
            '&:hover': {
              backgroundColor: 'red', 
            },
            boxShadow: 'none', 
          }}
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  ))}
</TableBody>

         
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <>
          <ResultDialog
            open={eklemeBasariliDialogOpen}
            onClose={() => setEklemeBasariliDialogOpen(false)}
            title={dialogSeverity === 'error' ? "Hata Oluştu" : "İşlem Başarılı"}
            message={dialogContent}
            severity={dialogSeverity}
          />

          <ResultDialog
            open={silmeBasariliDialogOpen}
            onClose={() => setSilmeBasariliDialogOpen(false)}
            title={dialogSeverity === 'error' ? "Hata Oluştu" : "Silme İşlemi Başarılı"}
            message={dialogContent}
            severity={dialogSeverity}
          />

    {renderDuzenlemeFormuDialog()}
    </>


    </Paper>
  );
};

export default Okullar;
