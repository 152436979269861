import React, { useRef, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
import Header from '../../home/components/Header';
import Footer from '../../home/components/Footer';
import GameTimer from '../components/Egzersizler/Timer';

function RotatingDiamondGame() {
  const canvasRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false); // Oynatma durumunu kontrol etmek için state
  const [currentScaleStep, setCurrentScaleStep] = useState(0);
  const scaleSteps = [0.3,  0.4, 0.6, 0.8, 1, 1.2, 1.4, 1.6, 1.8];
  
  const updateLetters = () => {
    const turkishAlphabet = ['A', 'B', 'C', 'Ç', 'D', 'E', 'F', 'G', 'Ğ', 'H', 'I', 'İ', 'J', 'K', 'L', 'M', 'N', 'O', 'Ö', 'P', 'R', 'S', 'Ş', 'T', 'U', 'Ü', 'V', 'Y', 'Z'];
    return Array.from({ length: 4 }, () => turkishAlphabet[Math.floor(Math.random() * turkishAlphabet.length)]);
  };
  
  const [letters, setLetters] = useState(updateLetters());

  useEffect(() => {
    let intervalId;
    if (isPlaying) {
      intervalId = setInterval(() => {
        let nextScaleStep = currentScaleStep + 1;
        if (nextScaleStep >= scaleSteps.length) {
          nextScaleStep = 0;
        }
        setLetters(updateLetters());
        setCurrentScaleStep(nextScaleStep);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [isPlaying, currentScaleStep]);
  useEffect(() => {
    const drawDiamond = () => {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      const sideLength = 100;
      const scale = scaleSteps[currentScaleStep];
      const centerX = canvas.width / 2;
      const centerY = canvas.height / 2;

      ctx.clearRect(0, 0, canvas.width, canvas.height); // Önceki çizimleri temizle
      ctx.save();
      ctx.translate(centerX, centerY);

      ctx.lineWidth = 5; // Çizgi kalınlığını ayarla

      const points = [
        { x: 0, y: -sideLength * scale },
        { x: sideLength * scale, y: 0 },
        { x: 0, y: sideLength * scale },
        { x: -sideLength * scale, y: 0 },
      ];

      ctx.beginPath();
      points.forEach((point, index) => {
        ctx.lineTo(point.x, point.y);
      });
      ctx.closePath();
      ctx.stroke(); // Kalın çizgilerle elmas çiz

      points.forEach((point, index) => {
        const radius = 20; // Dairenin yarıçapı
        ctx.beginPath();
        ctx.arc(point.x, point.y, radius, 0, Math.PI * 2);
        ctx.fillStyle = 'white'; // Daireleri beyazla doldur
        ctx.fill();
        ctx.font = 'bold 20px Arial'; // Yazı tipini ve boyutunu ayarla
        ctx.fillStyle = 'black'; // Yazı rengini siyah yap
        ctx.textAlign = 'center'; // Yazıyı dairelerin ortasına hizala
        ctx.textBaseline = 'middle';
        ctx.fillText(letters[index], point.x, point.y); // Harfleri yaz
      });

      ctx.restore();
    };

    // Oyun durumu değiştiğinde veya mevcut ölçek adımı değiştiğinde elması yeniden çiz
    if(isPlaying) {
      const intervalId = setInterval(() => {
        let nextScaleStep = currentScaleStep + 1;
        if (nextScaleStep >= scaleSteps.length) {
          nextScaleStep = 0; // Döngüyü başa sar
        }
        setLetters(updateLetters()); // Harfleri güncelle
        setCurrentScaleStep(nextScaleStep); // Ölçek adımını güncelle
        drawDiamond(); // Elması çiz
      }, 1000); // Her 1000 milisaniyede bir

      return () => clearInterval(intervalId); // Temizleme fonksiyonu
    }
  }, [isPlaying, currentScaleStep, letters]); // Bağımlılıkları güncelle


  return (
    <div>
      <Header />
      <div className="container">
        <div className="canvas-container">
        <div className="clock-timer-container">
        <GameTimer className="game-timer" isPlaying={isPlaying} />
        </div>
          <button className="play-button" onClick={() => setIsPlaying(!isPlaying)}>
            <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
          </button>
          <canvas ref={canvasRef} width={900} height={600} />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default RotatingDiamondGame;
