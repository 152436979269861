import React from 'react';
import { Route, Routes } from 'react-router-dom';
import NesneTakip from '../pages/Egzersizler/NesneTakip';
import SekilAvi from '../pages/Egzersizler/SekilAvi';
import ZigzagTracker from '../pages/Egzersizler/ZigzagTracker';
import PointTracker from '../pages/Egzersizler/NoktaTakip';
import WordGuessingGame from '../pages/Egzersizler/WordGuessingGame';
import RotatingDiamondGame from '../pages/Egzersizler/RotatingDiamondGame';



function EgzersizRouter() {
    return (
      <Routes>
      
          <Route path="/nesne-takip" element={<NesneTakip />} />
          <Route path="/sekil-avi" element={<SekilAvi />} />
          <Route path="/zigzag" element={<ZigzagTracker />} />
          <Route path="/nokta-takip" element={<PointTracker />} />
          <Route path="/kelime-tahmin" element={<WordGuessingGame />} />
          <Route path="/harf-dongusu" element={<RotatingDiamondGame />} />
          
      
      </Routes>
    );
  }
  
  export default EgzersizRouter;