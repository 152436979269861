import React, { useRef, useEffect, useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Slider, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
import Header from '../../home/components/Header';
import Footer from '../../home/components/Footer';
import GameTimer from '../components/Egzersizler/Timer';

function ZigzagTracker() {
  const canvasRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [position, setPosition] = useState(0);
  const [speed, setSpeed] = useState(10);
  const [orientation, setOrientation] = useState('horizontal');
  const [canvasSize, setCanvasSize] = useState({ width: 800, height: 600 });
  // Dikey mod için amplitüd değerini düşürdük
  const amplitude = orientation === 'horizontal' ? 200 : 400; 
  const wavelength = 200;

  useEffect(() => {
    const updateCanvasSize = () => {
      const width = window.innerWidth * 0.7; // Pencere genişliğinin %60'ı
      const height = window.innerHeight * 0.6; // Pencere yüksekliğinin %60'ı
      setCanvasSize({ width, height });
    };

    window.addEventListener('resize', updateCanvasSize);
    updateCanvasSize();

    return () => window.removeEventListener('resize', updateCanvasSize);
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const { width, height } = canvasSize;

    const drawHorizontal = () => {
      ctx.beginPath();
      ctx.lineWidth = 3;
      ctx.moveTo(0, height / 2);
      for (let x = 0; x < width; x += 1) {
        ctx.lineTo(x, height / 2 + Math.sin(x / wavelength * 2 * Math.PI) * amplitude);
      }
      ctx.stroke();

      const posY = height / 2 + Math.sin(position / wavelength * 2 * Math.PI) * amplitude;
      ctx.beginPath();
      ctx.arc(position, posY, 20, 0, 2 * Math.PI);
      ctx.fillStyle = 'red';
      ctx.fill();
    };

    const drawVertical = () => {
      ctx.beginPath();
      ctx.lineWidth = 4;
      // Dikey çizimin x koordinatlarını sağa kaydırmak için ayarlıyoruz
      const shift = (width - amplitude) / 2; // Amplitude artışına göre kaydırmak için shift değerini hesapla
      const startX = shift + (amplitude / 2); // Çizginin başlangıç X koordinatını sağa kaydır

      for (let y = 0; y < height; y++) {
        const x = startX + amplitude * Math.sin((y / wavelength) * 2 * Math.PI);
        ctx.lineTo(x, y);
      }
      ctx.stroke();

      // Topun x pozisyonunu, dalga üzerinde güncellenmiş pozisyonla ayarlıyoruz
      const posX = startX + amplitude * Math.sin((position / wavelength) * 2 * Math.PI);
      ctx.beginPath();
      ctx.arc(posX, position, 20, 0, 2 * Math.PI);
      ctx.fillStyle = 'red';
      ctx.fill();
    };

    const draw = () => {
      if (!isPlaying) return;
      ctx.clearRect(0, 0, width, height);
      
      if (orientation === 'horizontal') {
        drawHorizontal();
        setPosition((prevPosition) => (prevPosition + speed) % width);
      } else {
        drawVertical();
        setPosition((prevPosition) => (prevPosition + speed) % height);
      }
    };

    const intervalId = setInterval(draw, 20);

    return () => clearInterval(intervalId);
  }, [isPlaying, position, speed, amplitude, wavelength, canvasSize, orientation]);

  return (
    <div>
      <Header />
      <div className="container">
        <div className="canvas-container">
        <div className="clock-timer-container" >
        <GameTimer className="game-timer" isPlaying={isPlaying} />
        </div>
          <FormControl fullWidth style={{ marginBottom: '5px', width:'250px'  }}>
            <InputLabel id="orientation-select-label">Yön</InputLabel>
            <Select
              labelId="orientation-select-label"
              id="orientation-select"
              value={orientation}
              label="Yön"
              onChange={(e) => setOrientation(e.target.value)}
            >
              <MenuItem value="horizontal">Yatay</MenuItem>
              <MenuItem value="vertical">Dikey</MenuItem>
            </Select>
          </FormControl>

          <div className="speed-controls" style={{ marginBottom: '5px', width:'250px' }}>
            <Slider
              value={speed}
              onChange={(e, newValue) => setSpeed(Math.max(0.1, newValue))}
              aria-labelledby="speed-slider"
              valueLabelDisplay="auto"
              step={0.1}
              marks
              min={0.1}
              max={30}
          
              
            />
            <span> Hız: {speed.toFixed(1)} </span>
          </div>

          <button className="play-button" onClick={() => setIsPlaying(!isPlaying)}>
          <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
        </button>
          <canvas ref={canvasRef} width={canvasSize.width} height={canvasSize.height} />
        </div>
      
      </div>
      <Footer />
    </div>
  );
}

export default ZigzagTracker;
