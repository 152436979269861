import React, { useState, useRef, useEffect } from 'react';
import Header from '../../home/components/Header';
import Footer from '../../home/components/Footer';
import GameTimer from '../components/Egzersizler/Timer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, } from '@fortawesome/free-solid-svg-icons';



const colors = ['#E91E63', '#9C27B0', '#2196F3', '#4CAF50', '#FFC107'];

const NesneTakipTest = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [speed, setSpeed] = useState(6); 
  const [numberOfBalls, setNumberOfBalls] = useState(2);
  const canvasRef = useRef(null);
  const canvasWidth = 900;
  const canvasHeight = 600;

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    const createBalls = () => {
      const newBalls = [];
      for (let i = 0; i < numberOfBalls; i++) {
        const x = Math.random() * (canvasWidth - 100) + 50;
        const y = Math.random() * (canvasHeight - 100) + 50;
        const color = colors[Math.floor(Math.random() * colors.length)];
        const number = Math.floor(Math.random() * 15) + 1; 
        newBalls.push({ x, y, color, number });
      }
      return newBalls;
    };
    
    
    const drawBalls = (balls) => {
      ctx.clearRect(0, 0, canvasWidth, canvasHeight); 
      balls.forEach(ball => {      
        ctx.beginPath();
        ctx.arc(ball.x, ball.y, 25, 0, 2 * Math.PI);
        ctx.fillStyle = ball.color;
        ctx.fill();          
        ctx.strokeStyle = 'black'; 
        ctx.stroke();    
      });
    };    

    const update = () => {
      if (isPlaying) {
        const balls = createBalls();
        drawBalls(balls);
      }
    };

    const interval = setInterval(update, 2000 - speed * 100); 
    return () => clearInterval(interval);
  }, [isPlaying, speed, numberOfBalls]); 

  const increaseBalls = () => setNumberOfBalls(prev => Math.min(prev + 1, 10)); 
const decreaseBalls = () => setNumberOfBalls(prev => Math.max(prev - 1, 1)); 


  return (
    <div>
      <Header />
      <div className="container">
        <div className="canvas-container">
        <div className="clock-timer-container">
        <GameTimer className="game-timer" isPlaying={isPlaying} />
        </div>
        <div className="ball-control">
          <div className="ball-control-title">Top Sayısı:</div>
          <button onClick={decreaseBalls}>-</button>
          <span className="ball-count-display">{numberOfBalls}</span>
          <button onClick={increaseBalls}>+</button>
        </div>
      
        
          <button className="play-button" onClick={() => setIsPlaying(!isPlaying)}>
            <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
          </button>
         

       
          <div className="speed-controls">         
            <input type="range" value={speed} onChange={(e) => setSpeed(Math.max(1, parseInt(e.target.value)))} min="1" max="20" />
            <span> Hız: {speed} </span>
          </div>
        
          <canvas ref={canvasRef} width={canvasWidth} height={canvasHeight} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NesneTakipTest;
