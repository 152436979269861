import React from 'react';
import Header from '../components/Header';
import HeaderBanner from '../components/ui/Banner';
import Footer from '../components/Footer';
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';
import ComputerIcon from '@mui/icons-material/Computer'; // Örnek ikonlar
import GroupIcon from '@mui/icons-material/Group';
import DiamondIcon from '@mui/icons-material/Diamond';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';




function MainCard({ Icon, title, subtitle, bgColor }) {
  return (
    <Card sx={{
      minWidth: 275,
      boxShadow: 10,
      borderRadius: 2,
      background: bgColor,
      transition: '0.3s',
      '&:hover': {
        transform: 'scale(1.05)',
        boxShadow: 15,
      },
    }}>
      <CardContent sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff',
      }}>
        <Icon sx={{ fontSize: 60, mb: 2 }} />
        <Typography variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
          {title}
        </Typography>
        <Typography color="text.secondary"   sx={{
    fontFamily: '"Roboto Condensed", sans-serif',
    fontWeight: 700, 
  
    fontSize: 30,
    color: '#2D3250',
  
    textShadow: `
      1px 0 0 #fff,  
      -1px 0 0 #fff, 
      0 1px 0 #fff,  
      0 -1px 0 #fff 
    `,
  }}>
          {subtitle}
        </Typography>
      </CardContent>
    </Card>
  );
}

function Home() {
  return (
    <div>
      <Header />
      <HeaderBanner />

      <Box sx={{ flexGrow: 1, m: 4 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={3}>
            <MainCard 
              Icon={ComputerIcon}
              title="71k+"
              subtitle="Eğitim Kursu Sunulan"
              bgColor="linear-gradient(145deg, #7b1fa2, #9c27b0)"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <MainCard 
              Icon={GroupIcon}
              title="50k+"
              subtitle="Öğrenci Memnuniyeti"
              bgColor="linear-gradient(145deg, #00796b, #009688)"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <MainCard 
              Icon={DiamondIcon}
              title="200+"
              subtitle="Deneyimli Öğretmen"
              bgColor="linear-gradient(145deg, #c2185b, #e91e63)"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <MainCard 
              Icon={CheckCircleOutlineIcon}
              title="95%"
              subtitle="Başarı Oranı"
              bgColor="linear-gradient(145deg, #fbc02d, #ffc107)"
            />
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </div>
  );
}

export default Home;
