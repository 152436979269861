import React, { useState } from 'react';
import {  TextField, Button, FormControl, InputLabel, Select, MenuItem, Typography, Paper, Radio, RadioGroup, Grid, FormControlLabel } from '@mui/material';
import { API_URLS } from '../../../config/config';
import useFetchUserData from '../../../hooks/ogretmen/useFetchUserData';
import ResultDialog from '../../../pages/components/Auth/Dialog';


function BookUploadForm() {
    const [userData, setUserData] = useState(null);
    const [bookTitle, setBookTitle] = useState('');
    const [difficulty, setDifficulty] = useState('');
    const [file, setFile] = useState(null);
    const [content, setContent] = useState('');
    const [uploadType, setUploadType] = useState('file');
    const [dialogContent, setDialogContent] = useState('');
    const [dialogSeverity, setDialogSeverity] = useState('info');
    const [eklemeBasariliDialogOpen, setEklemeBasariliDialogOpen] = useState(false);


    useFetchUserData(setUserData);

    const handleTitleChange = (event) => {
        setBookTitle(event.target.value);
    };

    const handleDifficultyChange = (event) => {
        setDifficulty(event.target.value);
    };

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleContentChange = (event) => {
        setContent(event.target.value);
    };

    const handleUploadTypeChange = (event) => {
        setUploadType(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        let missingFields = [];
    
        if (!bookTitle) missingFields.push('Kitap Adı');
        if (!difficulty) missingFields.push('Zorluk Seviyesi');
        if (uploadType === 'file' ? !file : !content) missingFields.push('İçerik');
        if (!userData?._id) missingFields.push('Öğretmen ID');
    
        if (missingFields.length > 0) {
            setDialogContent(`Lütfen ${missingFields.join(', ')} alanlarını doldurunuz.`);
            setDialogSeverity('error');
            setEklemeBasariliDialogOpen(true);
            return;
        }
    
        const headers = {};
        let bodyData;
    
        if (uploadType === 'file') {
            const formData = new FormData();
            formData.append('title', bookTitle);
            formData.append('difficulty', difficulty);
            formData.append('teacherId', userData?._id);
            formData.append('file', file);
            bodyData = formData;
            // Don't set Content-Type for FormData; the browser will set it with the proper boundary
        } else {
            // For text content, send as JSON
            headers['Content-Type'] = 'application/json';
            bodyData = JSON.stringify({
                title: bookTitle,
                difficulty: difficulty,
                content: content,
                teacherId: userData?._id
            });
        }
    
        try {
            const response = await fetch(`${API_URLS.TEACHER_URL}kutuphane`, {
                method: 'POST',
                headers: headers,
                body: bodyData,
            });
    
            const responseData = await response.json();
            if (response.ok) {
                setDialogSeverity('success');
                setDialogContent('Yeni Kitap başarıyla eklendi.');
                setEklemeBasariliDialogOpen(true);
                setBookTitle('');
                setDifficulty('');
                setContent('');
                setFile(null);
            } else {
                throw new Error(responseData.message || 'Kitap eklenirken bir hata oluştu');
            }
        } catch (err) {
            setDialogSeverity('error');
            setDialogContent(err.message);
            setEklemeBasariliDialogOpen(true);
        }
    };     

    return (
        <Paper style={{ padding: '40px', maxWidth: '600px', margin: 'auto', width: '100%' }}>
          
        <Typography variant="h5" component="h3" marginBottom={2}>
                Kitap Ekle
            </Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="title"
                    label="Kitap Adı"
                    name="title"
                    value={bookTitle}
                    onChange={handleTitleChange}
                    autoFocus
                />
                <FormControl fullWidth margin="normal">
                    <InputLabel id="difficulty-label">Zorluk Seviyesi</InputLabel>
                    <Select
                        labelId="difficulty-label"
                        id="difficulty"
                        value={difficulty}
                        label="Zorluk Seviyesi"
                        onChange={handleDifficultyChange}
                    >
                        <MenuItem value="easy">Kolay</MenuItem>
                        <MenuItem value="medium">Orta</MenuItem>
                        <MenuItem value="hard">Zor</MenuItem>
                    </Select>
                </FormControl>
                <FormControl component="fieldset" margin="normal">
                    <RadioGroup row aria-label="uploadType" name="uploadType" value={uploadType} onChange={handleUploadTypeChange}>
                        <FormControlLabel value="file" control={<Radio />} label="Dosya Yükle" />
                        <FormControlLabel value="text" control={<Radio />} label="Metin Gir" />
                    </RadioGroup>
                </FormControl>
                {uploadType === 'file' ? (
                    <>
                        <input
                        accept=".txt, .docx"
                        style={{ display: 'none' }}
                        id="file-upload"
                        type="file"
                        onChange={handleFileChange}
                        />
                        <label htmlFor="file-upload">
                            <Button variant="contained" color="primary" component="span" fullWidth>
                                Dosya Yükle
                            </Button>
                        </label>
                    </>
                ) : (
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="content"
                        label="Metin İçeriği"
                        name="content"
                        multiline
                        rows={4}
                        value={content}
                        onChange={handleContentChange}
                    />
                )}
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={{ marginTop: '24px' }}
                >
                    Kitap Ekle
                </Button>
            </form>

          
            <>
     
        <ResultDialog
            open={eklemeBasariliDialogOpen}
            onClose={() => setEklemeBasariliDialogOpen(false)}
            title={dialogSeverity === 'error' ? "Hata Oluştu" : "İşlem Başarılı"}
            message={dialogContent}
            severity={dialogSeverity}
        />
    </>
        </Paper>
    );
}

export default BookUploadForm;
