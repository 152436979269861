import React, { useState, useEffect } from 'react';
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Refresh'; 
import { API_URLS } from '../../../config/config';
import useFetchUserData from '../../../hooks/ogretmen/useFetchUserData';

const Books = () => {
    const [books, setBooks] = useState([]);
    const [userData, setUserData] = useState(null);
    useFetchUserData(setUserData);

    const fetchBooks = async () => {
        if (!userData || !userData._id) {
            console.log("Kullanıcı bilgisi yüklenemedi veya kullanıcı ID'si bulunamadı.");    
            return; 
        }

        const userId = userData._id;  
        console.log("Kullanıcı ID: " + userId);

        try {
            const response = await fetch(`${API_URLS.TEACHER_URL}kutuphane/${userId}`);
            const data = await response.json();
            if (response.ok) {
                setBooks(data);
            } else {
                console.error('Kitapları yüklerken bir sorun oluştu: ' + data.message);
            }
        } catch (error) {
            console.error('Fetch işlemi sırasında hata oluştu:', error);
        }
    }; 

    useEffect(() => {
        fetchBooks();
    }, [userData]); 

    return (
        <TableContainer component={Paper} style={{ marginTop: '20px' }}>
            <Button
                startIcon={<RefreshIcon />}
                onClick={fetchBooks} 
                variant="contained"
                color="primary"
                style={{ marginBottom: '10px' }}
            >
                Yenile
            </Button>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Kitap Adı</TableCell>
                        <TableCell>Zorluk</TableCell>
                        <TableCell>İşlem</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {books.map((book) => (
                        <TableRow key={book._id}>
                            <TableCell>{book.title}</TableCell>
                            <TableCell>{book.difficulty}</TableCell>
                            <TableCell>
                                <IconButton>
                                    <EditIcon />
                                </IconButton>
                                <IconButton>
                                    <DeleteIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default Books;
