import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Typography, Drawer, List, ListItem, ListItemIcon, ListItemText, CssBaseline,  Collapse } from '@mui/material';
import {
  Menu as MenuIcon, Dashboard as DashboardIcon,
  Mail as MailIcon,
  School as SchoolIcon,
  Settings as SettingsIcon,
  Logout as LogoutIcon,
  ExpandLess,
  ExpandMore,
  Payment as PaymentIcon,
  Report as ReportIcon,
  LocationCity as CityIcon,
  Business as BranchIcon,
  Person as TeacherIcon,
  Event as EventIcon,
  List as ListIcon,
  ContactPhone as ContactPhoneIcon,
  AccountCircle as AccountCircleIcon,
  AdminPanelSettings as AdminPanelSettingsIcon, 
  Group as StudentsIcon
} from '@mui/icons-material';
import { API_URLS } from '../../config/config';

//API_URLS.ADMIN_URL +

function KurumsalDashboard() {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate(); 
  const [open, setOpen] = useState({});

  const handleClick = (menu) => {
    setOpen((prevOpen) => ({...prevOpen, [menu]: !prevOpen[menu]}));
  };


  const drawerWidth = 240;
  const appBarStyle = {
    width: `calc(100% - ${drawerOpen ? drawerWidth : 0}px)`,
    transition: 'width 0.3s',
  };
  const drawerStyle = {
    width: drawerWidth,
    flexShrink: 0,
  };
  const drawerPaperStyle = {
    width: drawerWidth,
  };


  const contentStyle = {
    flexGrow: 1,
    padding: '24px',
    transition: 'margin-left 0.3s',
    marginLeft: `${drawerOpen ? drawerWidth : 0}px`,
    marginTop: '64px', 
  };
  

  // Drawer açma/kapama fonksiyonu
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen); 
  };


  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('userToken');
      if (!token) {      
        navigate('/');
      } else {
        try {
          const response = await fetch(API_URLS.SCHOOL_URL +'me', {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
          if (response.ok) {
            const data = await response.json();
            setUserData(data);
           
          } else {
       throw new Error('Veri alınırken bir hata oluştu');
    
          }
        } catch (error) {
          console.error(error);
          navigate('/login');
        }
      }
    };

    fetchUserData();
  }, [navigate]); 

  const handleLogout = async () => {
    try {
      
      await fetch(API_URLS.SCHOOL_URL +'logout', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('userToken')}`,
          'Content-Type': 'application/json',
        },
      });
      // Frontend'de oturum bilgilerini temizle
      localStorage.removeItem('userToken');
      navigate('/login');
    } catch (error) {
      console.error('Çıkış işlemi sırasında bir hata oluştu', error);
    }
  };


  const menuItems = [
    { text: 'Anasayfa', icon: <DashboardIcon />, action: () => {} },
    {
      text: 'Kurumlar',
      icon: <SchoolIcon />,
      subMenus: [
        { text: 'Şehirler', icon: <CityIcon /> },
        { text: 'Şubeler', icon: <BranchIcon /> },
      ],
    },

    { text: 'Öğretmenler', icon: <TeacherIcon />, action: () => {} },
    { text: 'Öğrenciler', icon: <StudentsIcon />, action: () => {} },
    {
      text: 'Raporlar',
      icon: <ReportIcon />,
      subMenus: [
        { text: 'Öğretmenler', icon: <TeacherIcon /> },
        { text: 'Ödemeler', icon: <PaymentIcon /> },
        { text: 'Etkinlikler', icon: <EventIcon /> },
      ],
    },
    {
      text: 'Ödeme',
      icon: <PaymentIcon />,
      subMenus: [
        { text: 'Ödeme formu sayfası', icon: <PaymentIcon /> },
        { text: 'Ödeme listesi', icon: <ListIcon /> },
      ],
    },
    { text: 'Mail', icon: <MailIcon />, action: () => {} },
    {
      text: 'Ayarlar',
      icon: <SettingsIcon />,
      subMenus: [
        { text: 'İletişim', icon: <ContactPhoneIcon /> },
        { text: 'Profil ayarları', icon: <AccountCircleIcon /> },
        { text: 'Yetkilendirme rolleri', icon: <AdminPanelSettingsIcon /> },
      ],
    },
    { text: 'Çıkış Yap', icon: <LogoutIcon />, action: handleLogout },
  ];
  
  return (
    <div style={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" style={appBarStyle}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={toggleDrawer}
            style={{ marginRight: '20px', ...(drawerOpen && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
           Kurum Panel
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="persistent"
        open={drawerOpen}
        style={drawerStyle}
        sx={{ '& .MuiDrawer-paper': drawerPaperStyle }}
      >
    <List>
      {menuItems.map((item, index) => (
        <React.Fragment key={index}>
          <ListItem button onClick={() => item.subMenus ? handleClick(item.text) : item.action()}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
            {item.subMenus ? (open[item.text] ? <ExpandLess /> : <ExpandMore />) : null}
          </ListItem>
          {item.subMenus && (
            <Collapse in={open[item.text]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {item.subMenus.map((subItem, subIndex) => (
                  <ListItem button key={subIndex}>
                    <ListItemIcon>{subItem.icon}</ListItemIcon>
                    <ListItemText primary={subItem.text} />
                  </ListItem>
                ))}
              </List>
            </Collapse>
          )}
        </React.Fragment>
      ))}
    </List>
      </Drawer>
      <main style={contentStyle}>
        <Typography paragraph>
          Ana içerik buraya gelecek. Dashboard'unuzun ana sayfasını özelleştirebilirsiniz.
        </Typography>
        <Typography paragraph>
          Hoş Geldiniz Sayın - {userData && userData.okul_adi}
        </Typography>
      </main>
    </div>
  );
}

export default KurumsalDashboard;
