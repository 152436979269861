import React, { useRef, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, } from '@fortawesome/free-solid-svg-icons';
import Header from '../../home/components/Header';
import Footer from '../../home/components/Footer';
import GameTimer from '../components/Egzersizler/Timer';


function PointTracker() {
  const canvasWidth = 800;
  const canvasHeight = 600;
  const canvasRef = useRef(null);
  const trailLength = 20;
  const trail = useRef([]);
  const [isPlaying, setIsPlaying] = useState(false);


  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    const drawTrail = () => {
      if (!isPlaying) return; 
      ctx.clearRect(0, 0, canvasWidth, canvasHeight); 

      // Kuyrukta her bir noktayı çiz
      trail.current.forEach((point, index) => {
        const opacity = (trailLength - index) / trailLength; 
        const radius = 20 + 5 * (1 - opacity); 

        // Gradient oluştur
        const gradient = ctx.createRadialGradient(point.x, point.y, 0, point.x, point.y, radius);
        gradient.addColorStop(0, `rgba(255, 69, 0, ${opacity})`); 
        gradient.addColorStop(1, `rgba(255, 165, 0, 0)`); 

        ctx.fillStyle = gradient;
        ctx.beginPath();
        ctx.arc(point.x, point.y, radius, 0, Math.PI * 2);
        ctx.fill();
      });

      // Kuyruğu güncelle
      if (trail.current.length > trailLength) {
        trail.current.shift(); // Kuyruğun maksimum uzunluğunu koru
      }

      // Mouse'un şu anki konumunda belirgin bir siyah nokta çiz  
    if (trail.current.length > 0) {
        const latestPoint = trail.current[trail.current.length - 1];
        ctx.beginPath();
        ctx.fillStyle = 'black'; // Noktanın rengi
        ctx.arc(latestPoint.x, latestPoint.y, 20, 0, Math.PI * 2); // Noktanın yarıçapını 20 olarak artır
        ctx.fill();
    }  

      requestAnimationFrame(drawTrail);
    };

    drawTrail();
  }, [isPlaying]);

  useEffect(() => {
    const updateMousePosition = (event) => {
      if (!isPlaying) return; // Eğer oyun duraklatılmışsa, mouse pozisyonunu güncelleme
      
      const rect = canvasRef.current.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      trail.current.push({ x, y }); // Güncellenen pozisyonu kuyruğa ekle
    };

    window.addEventListener('mousemove', updateMousePosition);

    return () => {
      window.removeEventListener('mousemove', updateMousePosition);
    };
  }, [isPlaying]);


  return (
    <div>
    <Header />
    <div className="container">
      <div className="canvas-container">
      <div className="clock-timer-container">
        <GameTimer className="game-timer" isPlaying={isPlaying} />
        </div>
        <button className="play-button" onClick={() => setIsPlaying(!isPlaying)}>
          <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
        </button>
        <canvas ref={canvasRef} width={canvasWidth} height={canvasHeight} />
      </div>
    </div>
    <Footer />
  </div>
  );
}

export default PointTracker;
