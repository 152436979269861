import React from 'react';
import { Route, Routes } from 'react-router-dom';
import KurumsalLoginForm from '../pages/Auth/Login/Kurumsal';
import OgrenciLoginForm from '../pages/Auth/Login/Ogrenci';
import OgretmenLoginForm from '../pages/Auth/Login/Ogretmen';
import AdminLoginForm from '../pages/Auth/Login/Admin';

import KurumsalForgotPasswordForm from '../pages/Auth/ResetPassword/Forgot/KurumsalForgotPassword';
import OgretmenForgotPasswordForm from '../pages/Auth/ResetPassword/Forgot/TeacherForgotPassword';
import OgrenciForgotPasswordForm from '../pages/Auth/ResetPassword/Forgot/StudentForgotPassword';
import AdminForgotPasswordForm from '../pages/Auth/ResetPassword/Forgot/AdminForgotPassword';

import KurumsalResetPasswordForm from '../pages/Auth/ResetPassword/Reset/Kurumsal';
import OgretmenResetPasswordForm from '../pages/Auth/ResetPassword/Reset/Ogretmen';
import OgrenciResetPasswordForm from '../pages/Auth/ResetPassword/Reset/Ogrenci';
import AdminResetPasswordForm from '../pages/Auth/ResetPassword/Reset/Admin';

import KurumsalRegisterForm from '../pages/Auth/Register/Okul';
import OgretmenRegisterForm from '../pages/Auth/Register/Ogretmen';
import OgrenciRegisterForm from '../pages/Auth/Register/Ogrenci';

import KurumsalDashboard from '../dashboard/kurumsal/Dashboard';
import OgretmenDashboard from '../dashboard/ogretmen/Dashboard';
import OgrenciDashboard from '../dashboard/ogrenci/Dashboard';
import AdminDashboard from '../dashboard/admin/Dashboard';


function AuthRouter() {
    return (
      <Routes>       
      <Route path="/okul-kayit" element={<KurumsalRegisterForm />} />
      <Route path="/ogrenci-kayit" element={<OgrenciRegisterForm />} />
      <Route path="/ogretmen-kayit" element={<OgretmenRegisterForm />} />         
      <Route path="/kurumsal-giris" element={<KurumsalLoginForm />} />
      <Route path="/ogrenci-giris" element={<OgrenciLoginForm />} />
      <Route path="/ogretmen-giris" element={<OgretmenLoginForm />} />
      <Route path="/admin-giris" element={<AdminLoginForm />} />
           
         
      <Route path="/kurumsal-dashboard" element={<KurumsalDashboard />} />
      <Route path="/ogretmen-dashboard" element={<OgretmenDashboard />} />    
      <Route path="/ogrenci-dashboard" element={<OgrenciDashboard />} />    
      <Route path="/admin-dashboard" element={<AdminDashboard />} />    
      
      <Route path="/kurumsal/forgot-password" element={<KurumsalForgotPasswordForm />} />
      <Route path="/ogretmen/forgot-password" element={<OgretmenForgotPasswordForm />} />
      <Route path="/ogrenci/forgot-password" element={<OgrenciForgotPasswordForm />} />
      <Route path="/admin/forgot-password" element={<AdminForgotPasswordForm />} />
    
      <Route path="/kurumsal/reset-password/:resetToken" element={<KurumsalResetPasswordForm />} />
      <Route path="/ogretmen/reset-password/:resetToken" element={<OgretmenResetPasswordForm />} />
      <Route path="/ogrenci/reset-password/:resetToken" element={<OgrenciResetPasswordForm />} />
      <Route path="/admin/reset-password/:resetToken" element={<AdminResetPasswordForm />} />
    
      </Routes>
    );
  }
  
  export default AuthRouter;