import React from 'react';
import { Box, Container, Grid, Link, Typography, IconButton } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';

const Footer = () => {
  return (
    <Box sx={{
        background: 'linear-gradient(45deg, #0A192F 0%, #203A43 25%, #2C5364 50%, #47688E 75%, #6F9AB8 100%)',
        backdropFilter: 'blur(10px)',
        backgroundSize: '200% 200%',
        boxShadow: '3px 3px 10px 0px rgba(81, 89, 105, 0.5), 5px 5px 3px 5px rgba(167, 226, 241, 0.3), 00px 0px 4px 5px rgba(0, 0, 0, 0.3)',   
        borderRadius: '20px 20px 0 0',      
        position: 'relative', 
        '&:after': { 
          content: '""', 
          position: 'absolute',
          bottom: 10, 
          left: 0,
          width: '100%', 
          height: '20px', 
          backgroundImage: 'linear-gradient(135deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 50%, rgba(0,0,0,0) 100%)', 
          zIndex: 1, 
          
        },           
        animation: 'gradient 15s ease infinite',
        '& .MuiToolbar-root': {
          minHeight: '100px',
        },        
        color:'white', p: 8,  fontSize: '1rem' ,
    
       
        }} component="footer">

<Container maxWidth="lg">
  <Grid container spacing={4}>
    <Grid item xs={12} sm={4}>
      <Typography variant="h6" color="inherit" gutterBottom style={{ fontWeight: 'bold' }}>
        Şirketimiz
      </Typography>
      <ul style={{ listStyle: 'none', padding: 0 }}>
        <li>
          <Link href="#" color="inherit" underline="none" style={{ fontWeight: 'bold' }}>Hakkımızda</Link>
        </li>
        <li>
          <Link href="#" color="inherit" underline="none" style={{ fontWeight: 'bold' }}>Kariyer</Link>
        </li>
        <li>
          <Link href="#" color="inherit" underline="none" style={{ fontWeight: 'bold' }}>Basın</Link>
        </li>
      </ul>
    </Grid>
    <Grid item xs={12} sm={4}>
      <Typography variant="h6" color="inherit" gutterBottom style={{ fontWeight: 'bold' }}>
        Topluluk
      </Typography>
      <ul style={{ listStyle: 'none', padding: 0 }}>
        <li>
          <Link href="#" color="inherit" underline="none" style={{ fontWeight: 'bold' }}>Yardım Merkezi</Link>
        </li>
        <li>
          <Link href="#" color="inherit" underline="none" style={{ fontWeight: 'bold' }}>Kullanım Koşulları</Link>
        </li>
        <li>
          <Link href="#" color="inherit" underline="none" style={{ fontWeight: 'bold' }}>Gizlilik Politikası</Link>
        </li>
      </ul>
    </Grid>
    <Grid item xs={12} sm={4}>
      <Typography variant="h6" color="inherit" gutterBottom style={{ fontWeight: 'bold' }}>
        Sosyal Medya
      </Typography>
      <IconButton href="#" color="inherit">
        <InstagramIcon />
      </IconButton>
      <IconButton href="#" color="inherit">
        <FacebookIcon />
      </IconButton>
      <IconButton href="#" color="inherit">
        <TwitterIcon />
      </IconButton>
    </Grid>
  </Grid>
  <Box mt={5}>
    <Typography variant="body2" color="inherit" align="center" style={{ fontWeight: 'bold' }}>
      © {new Date().getFullYear()} HizliOkumaveAnlama.Com Tüm hakları saklıdır.
    </Typography>
  </Box>
</Container>

    </Box>
  );
};

export default Footer;
