import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Typography, Drawer, List, ListItem, ListItemIcon, ListItemText, CssBaseline, Collapse } from '@mui/material';
import { Menu as MenuIcon, Dashboard as DashboardIcon,  Mail as MailIcon,  Settings as SettingsIcon,  Logout as LogoutIcon,  Business as BranchIcon,  School as SchoolIcon,
  Group as StudentsIcon,  Person as TeacherIcon,  Report as ReportIcon,  ExpandLess,  ExpandMore,  LibraryBooks as LibraryBooksIcon,  AddBox as AddBoxIcon,
  FormatListBulleted as FormatListBulletedIcon,  Assignment as AssignmentIcon } from '@mui/icons-material';
  import { API_URLS } from '../../config/config';
import BookUploadForm from './kutuphane/Add';
import BookListe from './kutuphane/Liste';
import useFetchUserData from '../../hooks/ogretmen/useFetchUserData';



function OgretmenDashboard() {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate(); 
  const [open, setOpen] = useState({});
  const [selectedMenu, setSelectedMenu] = useState('');
   
  useFetchUserData(setUserData);


  const handleClick = (menu) => {
    setOpen((prevOpen) => ({...prevOpen, [menu]: !prevOpen[menu]}));
  };

  const drawerWidth = 240;
  const appBarStyle = {
    width: `calc(100% - ${drawerOpen ? drawerWidth : 0}px)`,
    transition: 'width 0.3s',
  };
  const drawerStyle = {
    width: drawerWidth,
    flexShrink: 0,
  };
  const drawerPaperStyle = {
    width: drawerWidth,
  };

  const contentStyle = {
    flexGrow: 1,
    padding: '24px',
    transition: 'margin-left 0.3s',
    marginLeft: `10px`,
    marginTop: '64px', 
  };  

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen); 
  };
 
  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('userToken');
      if (!token) {      
        navigate('/ogretmen-giris');
      } else {
        try {
          const response = await fetch(API_URLS.TEACHER_URL + 'me', {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
          if (response.ok) {
            const data = await response.json();
            setUserData(data);           
          } else {
       throw new Error('Veri alınırken bir hata oluştu');
    
          }
        } catch (error) {
          console.error(error);
          navigate('/ogretmen-giris');
        }
      }
    };

    fetchUserData();
  }, [navigate]); 

  const handleLogout = async () => {
    try {
      
      await fetch(API_URLS.TEACHER_URL + 'logout', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('userToken')}`,
          'Content-Type': 'application/json',
        },
      });
       localStorage.removeItem('userToken');
      navigate('/login');
    } catch (error) {
      console.error('Çıkış işlemi sırasında bir hata oluştu', error);
    }
  };

  const menuItems = [
    { text: 'Dashboard', icon: <DashboardIcon /> },
    { text: 'Şubeler', icon: <BranchIcon /> },
    { text: 'Öğrenciler', icon: <StudentsIcon /> },
    { text: 'Ders Program', icon: <SchoolIcon /> },
    {
      text: 'Kütüphane',
      icon: <LibraryBooksIcon />,
      subMenus: [
        { text: 'Kitap Ekle', icon: <AddBoxIcon />, action: () => setSelectedMenu('KitapEkle') },
        { text: 'Tüm Liste', icon: <FormatListBulletedIcon />, action: () => setSelectedMenu('KitapListe') },
        { text: 'Testler', icon: <AssignmentIcon /> },        
      ],
    },
    { text: 'Ödev Ver', icon: <AddBoxIcon /> },
    {
      text: 'Raporlar',
      icon: <ReportIcon />,
    
      subMenus: [
        { text: 'Öğretmenler', icon: <TeacherIcon /> },
        { text: 'Öğrenciler', icon: <StudentsIcon /> },       
      ],
    }, 
    { text: 'Mail', icon: <MailIcon /> }, 
    { text: 'Ayarlar', icon: <SettingsIcon /> }, 
    { text: 'Çıkış Yap', icon: <LogoutIcon />, action: handleLogout },
   
  ];

  const renderContent = () => {
  switch (selectedMenu) {
    case 'KitapEkle':
      return <BookUploadForm />
    break;
    case 'KitapListe':
      return <BookListe />
    break;

    default:      
    return (
      <Typography paragraph>
        Hoş Geldiniz, {userData && userData.ad}
      </Typography>
    );
  }

};
  
return (

  <div style={{ display: 'flex' }}>
  <CssBaseline />
  <AppBar position="fixed" style={appBarStyle}>
    <Toolbar>
      <IconButton 
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={toggleDrawer}
        style={{ marginRight: '20px', ...(drawerOpen && { display: 'none' }) }}
      >
        <MenuIcon />
      </IconButton>
      <Typography variant="h6" noWrap>
       Kurum Panel
      </Typography>
    </Toolbar>
  </AppBar>
  <Drawer
    variant="persistent"
    open={drawerOpen}
    style={drawerStyle}
    sx={{ '& .MuiDrawer-paper': drawerPaperStyle }}
  >

<List>
{menuItems.map((item, index) => (
  <React.Fragment key={index}>
    <ListItem button onClick={() => item.action ? item.action() : handleClick(item.text)}>
      <ListItemIcon>{item.icon}</ListItemIcon>
      <ListItemText primary={item.text} />
      {item.subMenus ? (open[item.text] ? <ExpandLess /> : <ExpandMore />) : null}
    </ListItem>
    {item.subMenus && (
      <Collapse in={open[item.text]} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {item.subMenus.map((subItem, subIndex) => (
            <ListItem button key={subIndex} onClick={subItem.action}>
              <ListItemIcon>{subItem.icon}</ListItemIcon>
              <ListItemText primary={subItem.text} />
            </ListItem>
          ))}
        </List>
      </Collapse>
    )}
  </React.Fragment>
))}
</List>

    </Drawer>
    <main style={contentStyle}>
        {renderContent()}
      </main>
  </div>
);
}

export default OgretmenDashboard;
